export const SIGNUP_STAGES = {
  INITIAL: "initial",
  BASIC_INFO: "basic-info",
  OAUTH_INFO: "oauth-info",
  RESTRICTIONS: "restrictions",
  PREFERENCES: "preferences",
  ABILITY: "ability",
  COMPLETE: "complete",
};

// Define the order of stages
export const SIGNUP_FLOWS = {
  DEFAULT: [
    SIGNUP_STAGES.INITIAL,
    SIGNUP_STAGES.BASIC_INFO,
    SIGNUP_STAGES.RESTRICTIONS,
    SIGNUP_STAGES.PREFERENCES,
    SIGNUP_STAGES.ABILITY,
    SIGNUP_STAGES.COMPLETE,
  ],
  OAUTH: [
    SIGNUP_STAGES.OAUTH_INFO,
    SIGNUP_STAGES.RESTRICTIONS,
    SIGNUP_STAGES.PREFERENCES,
    SIGNUP_STAGES.ABILITY,
    SIGNUP_STAGES.COMPLETE,
  ],
};

// Helper functions
export const getNextStage = (currentStage, isOAuth = false) => {
  const flow = isOAuth ? SIGNUP_FLOWS.OAUTH : SIGNUP_FLOWS.DEFAULT;
  const currentIndex = flow.indexOf(currentStage);
  console.log("NEXT STAGE");
  console.log(currentStage, flow, currentIndex, currentIndex < flow.length - 1 ? flow[currentIndex + 1] : null);
  return currentIndex < flow.length - 1 ? flow[currentIndex + 1] : null;
};

export const getPreviousStage = (currentStage, isOAuth = false) => {
  const flow = isOAuth ? SIGNUP_FLOWS.OAUTH : SIGNUP_FLOWS.DEFAULT;
  const currentIndex = flow.indexOf(currentStage);
  console.log("PREVIOUS STAGE");
  console.log(currentStage, flow, currentIndex, currentIndex > 0 ? flow[currentIndex - 1] : null);
  return currentIndex > 0 ? flow[currentIndex - 1] : null;
};

export const isValidStage = (stage) => {
  console.log("VALID STAGE");
  console.log(stage, Object.values(SIGNUP_STAGES).includes(stage));
  return Object.values(SIGNUP_STAGES).includes(stage);
};
