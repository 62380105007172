//Utility functions for recipes
import { pluralize } from "inflection";

//Convert ingredient quantity (decimal) to fractional string
export const decimalToFraction = (decimal) => {
  if (typeof decimal === "string" && /^\d{1,3}\/\d{1,3}$/.test(decimal)) {
    return decimal;
  }

  decimal = Number(decimal);
  if (isNaN(decimal)) {
    return "1";
  }

  // Handle whole numbers with fractional parts
  const wholeNumber = Math.floor(decimal);
  const fractionalPart = decimal - wholeNumber;

  // If it's a whole number, return it as is
  if (fractionalPart === 0) {
    return wholeNumber.toString();
  }

  const tolerance = 1.0e-6;
  let bestNumerator = 1;
  let bestDenominator = 1;
  let minError = Math.abs(fractionalPart - bestNumerator / bestDenominator);

  // Rest of fraction calculation now works with just the fractional part
  for (let denominator = 1; denominator <= 100; ++denominator) {
    for (let numerator = 1; numerator <= denominator; ++numerator) {
      let error = Math.abs(fractionalPart - numerator / denominator);
      if (error < minError) {
        minError = error;
        bestNumerator = numerator;
        bestDenominator = denominator;
      }
    }
  }

  if (minError < tolerance) {
    const fractionPart = bestNumerator + "/" + bestDenominator;
    return wholeNumber ? `${wholeNumber} ${fractionPart}` : fractionPart;
  } else {
    // For decimals that are common repeating fractions in recipes
    const commonRepeatingFractions = {
      // Halves
      0.5: "1/2",
      // Thirds
      0.333: "1/3",
      0.33: "1/3",
      0.666: "2/3",
      0.66: "2/3",
      // Quarters
      0.25: "1/4",
      0.75: "3/4",
      // Sixths
      0.166: "1/6",
      0.833: "5/6",
      // Eighths
      0.125: "1/8",
      0.375: "3/8",
      0.625: "5/8",
      0.875: "7/8",
      // Sixteenths
      0.062: "1/16",
      0.187: "3/16",
      0.312: "5/16",
      0.437: "7/16",
      0.562: "9/16",
      0.687: "11/16",
      0.812: "13/16",
      0.937: "15/16",
    };
    const fractionPart = commonRepeatingFractions[fractionalPart.toFixed(3)];
    if (fractionPart) {
      return wholeNumber ? `${wholeNumber} ${fractionPart}` : fractionPart;
    }
    return decimal.toString();
  }
};

export const convertToTitleCase = (str) => {
  if (typeof str !== "string") {
    return str;
  }

  var i, j, lowers, uppers;
  str = str.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv", "Bbq"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

  return str;
};

export const formatAmountUnit = (ingredient) => {
  const unit = ingredient.unit.display;
  let formattedAmount = "";
  let formattedUnit = "";

  if (unit && unit.magnitude && unit.magnitude !== "") {
    formattedAmount = decimalToFraction(unit.magnitude);
  }

  const hiddenUnits = ["not_exact", "self"];

  if (unit && unit?.text && unit?.text !== "" && !hiddenUnits.includes(unit.text)) {
    formattedUnit = unit.text;
  }

  if (unit && unit.text === "c") {
    formattedUnit = "cup";
  }

  let showSpace = false;

  if (formattedUnit !== "") {
    showSpace = true;
  }

  return `${formattedAmount} ${formattedUnit}${showSpace ? " " : ""}`;
};

export const formatIngredient = (ingredient, count = 1, type = null) => {
  //Convert ingredient data to a string for the top line
  let formattedName =
    ingredient.name && ingredient.name !== "" ? convertToTitleCase(ingredient.name) : "Unknown Ingredient";

  if (count && count > 1 && (type === "count" || type === "unknown")) {
    formattedName = pluralize(formattedName);
  }

  return `${formattedName}`;
};

export const formatState = (ingredient) => {
  let formattedState = "";

  if (ingredient.state && ingredient.state !== "") {
    formattedState = ingredient.state.toTitleCase();
  }

  if (formattedState !== "") {
    return `, ${formattedState}`;
  }
};

export const generateRecipeLdJson = (recipe) => {
  if (!recipe) return null;

  try {
    // Base recipe structure
    const recipeLD = {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      name: recipe.name,
      author: {
        "@type": "Person",
        name: recipe.user.username,
      },
      datePublished: recipe.date,
      description: recipe.description || "",
      image: recipe.data && recipe.data.length > 0 ? recipe.data.map((img) => img.url) : [],
    };

    // Add recipe category/course if available
    if (recipe.course?.name) {
      recipeLD.recipeCategory = recipe.course.name;
    }

    // Add cuisine if available
    if (recipe.cuisine?.name) {
      recipeLD.recipeCuisine = recipe.cuisine.name;
    }

    // Add keywords from tags
    if (recipe.tags && recipe.tags.length > 0) {
      recipeLD.keywords = recipe.tags.map((tag) => tag.text).join(",");
    }

    // Add source attribution if available
    if (recipe.source) {
      // Handle different source types
      switch (recipe.source.type) {
        case "Adapted from":
          recipeLD.author = {
            "@type": "Person",
            name: recipe.user.username,
          };
          recipeLD.creditText = `Adapted from ${recipe.source.text}`;
          if (recipe.source.url) {
            recipeLD.isBasedOn = recipe.source.url;
          }
          break;

        case "Imported from":
          // For direct imports, credit original source as author
          recipeLD.author = {
            "@type": "Organization",
            name: recipe.source.text,
          };
          if (recipe.source.url) {
            recipeLD.mainEntityOfPage = {
              "@type": "WebPage",
              "@id": recipe.source.url,
            };
          }
          break;

        case "Generated with":
          recipeLD.author = {
            "@type": "Person",
            name: recipe.user.username,
          };
          recipeLD.creditText = `Generated with ${recipe.source.text}`;
          break;

        default:
          // For other source types (Inspired by, Based on, etc)
          if (recipe.source.text) {
            recipeLD.creditText = `${recipe.source.type} ${recipe.source.text}`;
          }
          if (recipe.source.url) {
            recipeLD.isBasedOn = recipe.source.url;
          }
          break;
      }
    }

    // Add nutrition if available
    if (recipe.nutrition?.macros) {
      recipeLD.nutrition = {
        "@type": "NutritionInformation",
      };

      // Map common nutrition fields
      const nutritionMap = {
        calories: "calories",
        protein: "proteinContent",
        carbohydrates: "carbohydrateContent",
        fat: "fatContent",
        fiber: "fiberContent",
        sugar: "sugarContent",
        sodium: "sodiumContent",
      };

      for (const macro of recipe.nutrition.macros) {
        const schemaKey = nutritionMap[macro.name?.toLowerCase()];
        if (schemaKey) {
          recipeLD.nutrition[schemaKey] = `${macro.amount}${macro.unit?.shortName || "g"}`;
        }
      }
    }

    // Add cooking time if available
    if (recipe.timing) {
      if (recipe.timing.prep) {
        recipeLD.prepTime = `PT${recipe.timing.prep.hours || 0}H${recipe.timing.prep.minutes || 0}M`;
      }
      if (recipe.timing.cook) {
        recipeLD.cookTime = `PT${recipe.timing.cook.hours || 0}H${recipe.timing.cook.minutes || 0}M`;
      }
      if (recipe.timing.total) {
        recipeLD.totalTime = `PT${recipe.timing.total.hours || 0}H${recipe.timing.total.minutes || 0}M`;
      }
    }

    // Add yield/servings
    if (recipe.servings) {
      recipeLD.recipeYield = `${recipe.servings} serving${recipe.servings > 1 ? "s" : ""}`;
    }

    // Process ingredients from all sections
    const ingredients = [];
    const instructions = [];

    recipe.sections.forEach((section, sectionIndex) => {
      // Add section ingredients
      section.ingredients?.forEach((ingredient) => {
        let ingredientText = "";

        // Format amount and unit
        if (ingredient.unit?.display) {
          if (ingredient.unit.display.magnitude) {
            // Convert decimal to fraction
            const fraction = decimalToFraction(ingredient.unit.display.magnitude);
            ingredientText += `${fraction} `;
          }
          if (
            ingredient.unit.display.text &&
            ingredient.unit.display.text !== "not_exact" &&
            ingredient.unit.display.text !== "self"
          ) {
            ingredientText += `${ingredient.unit.display.text} `;
          }
        }

        // Add ingredient name
        ingredientText += ingredient.name;

        // Add state if present
        if (ingredient.state) {
          ingredientText += `, ${ingredient.state}`;
        }

        // Add note if present
        if (ingredient.note) {
          ingredientText += ` (${ingredient.note})`;
        }

        ingredients.push(ingredientText.trim());
      });
      // Add section instructions
      if (section.steps?.length > 0) {
        if (recipe.sections.length > 1 && section.title) {
          // Create a HowToSection for each titled section
          instructions.push({
            "@type": "HowToSection",
            name: section.title,
            itemListElement: section.steps.map((step, stepIndex) => ({
              "@type": "HowToStep",
              text: step.text,
              position: stepIndex + 1,
            })),
          });
        } else {
          // For single sections or untitled sections, add steps directly
          section.steps.forEach((step) => {
            instructions.push({
              "@type": "HowToStep",
              text: step.text,
              position: instructions.length + 1,
            });
          });
        }
      }
    });

    // Add ingredients and instructions to recipe
    if (ingredients.length > 0) {
      recipeLD.recipeIngredient = ingredients;
    }

    if (instructions.length > 0) {
      if (recipe.sections.length > 1) {
        // For multiple sections, use the array of HowToSections
        recipeLD.recipeInstructions = {
          "@type": "ItemList",
          itemListElement: instructions,
        };
      } else {
        // For single section, use array of HowToSteps directly
        recipeLD.recipeInstructions = instructions;
      }
    }

    // Add aggregated rating if available
    if (recipe.interactions?.reviewsData?.aggregatedRating > 0) {
      recipeLD.aggregateRating = {
        "@type": "AggregateRating",
        ratingValue: recipe.interactions.reviewsData.aggregatedRating,
        ratingCount: recipe.interactions.reviewsData.reviews.total,
        bestRating: "5",
        worstRating: "1",
      };
    }

    return JSON.stringify(recipeLD);
  } catch (error) {
    console.error("Error generating recipe LD+JSON:", error);
    return null;
  }
};
