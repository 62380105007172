import api from "../api/api";

import store, { history } from "../store";

import { setAlert } from "./alert";
import { getCroppedImg } from "../utils/image";

//Redux types
import {
  GET_HOME_FEED,
  HOME_FEED_ERROR,
  GET_FEED_POST,
  SET_HOME_FEED,
  GET_FEED_POST_ERROR,
  GET_POST_COMMENTS,
  GET_POST_COMMENTS_RESET,
  GET_POST_COMMENTS_ERROR,
  SET_POST_INTERACTIONS,
  FEED_DELETE_COMMENT,
  REFRESH_HOME_FEED,
} from "./types";

export const getHomeFeed = () => async (dispatch) => {
  try {
    //Reset loading, and error values
    dispatch({
      type: GET_HOME_FEED,
    });
    //Get state
    const state = store.getState();
    const feedState = state.feed;

    /*If feed already loaded, don't make another call
    if (feedState.isEndOfFeed) {
      console.log("End of feed");
      return;
    }*/

    const res = await api.get(`/feed/posts/${feedState.offset}`);

    const newPosts = feedState.posts ? [...feedState.posts, ...res.data.posts] : res.data.posts;

    const newOffset = feedState.offset + res.data.numPosts;

    const payloadData = {
      posts: newPosts,
      offset: newOffset,
      isEndOfFeed: res.data.isEndOfFeed,
      numPosts: res.data.numPosts,
    };

    dispatch({
      type: SET_HOME_FEED,
      payload: payloadData,
    });
  } catch (error) {
    dispatch({
      type: HOME_FEED_ERROR,
    });
  }
};

export const refreshHomeFeed = () => async (dispatch) => {
  try {
    dispatch({
      type: REFRESH_HOME_FEED,
    });

    const res = await api.get(`/feed/posts/0`);

    const payloadData = {
      posts: res.data.posts,
      offset: res.data.numPosts,
      isEndOfFeed: res.data.isEndOfFeed,
      numPosts: res.data.numPosts,
    };

    dispatch({
      type: SET_HOME_FEED,
      payload: payloadData,
    });
  } catch (error) {
    dispatch({
      type: HOME_FEED_ERROR,
    });
  }
};

export const getPostData =
  (postid, index, repostuser = null) =>
  async (dispatch) => {
    try {
      //TO DO: Check if post already loaded earlier, avoid an extra call
      const res = await api.post(`/post/feed/`, { postid, repostuser }, { timeout: 30000 }); //Extend to 30s timeout, 20 calls at once, allow for mobile

      dispatch({
        type: GET_FEED_POST,
        payload: {
          index: index,
          data: res.data,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_FEED_POST_ERROR,
        payload: {
          index,
          postid,
        },
      });
    }
  };

export const getCommentsForPost = (postid) => async (dispatch) => {
  try {
    const res = await api.get(`/post/comments/${postid}`);

    dispatch({
      type: GET_POST_COMMENTS,
      payload: res.data.comments,
    });
  } catch (error) {
    dispatch({
      type: GET_POST_COMMENTS_ERROR,
    });
    console.log(error);
  }
};

export const closePostComments = () => (dispatch) => {
  dispatch({
    type: GET_POST_COMMENTS_RESET,
  });
};

export const commentOnPost = (postid, comment) => async (dispatch) => {
  try {
    const body = {
      text: comment,
    };
    const res = await api.post(`/post/comment/${postid}`, body);

    console.log(res.data);

    //Add my comment to the top of comments
    const state = store.getState();

    const singleComment = {
      _id: res.data.comment._id,
      text: comment,
      user: {
        _id: state.auth.user._id,
        username: state.auth.user.username,
        picture: state.profile.currentProfile.picture,
      },
      date: Date.now(),
      likes: [],
      replies: [],
    };

    const newComments = [singleComment, ...state.feed.comments];

    dispatch({
      type: GET_POST_COMMENTS,
      payload: newComments,
    });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const createPost =
  ({ postText, videoUrl }) =>
  async (dispatch) => {
    try {
      //Form data
      const formData = new FormData();

      //Append cropped images
      const state = store.getState();

      const images = state.media.images;

      if (postText.trim() === "" && images.length === 0 && !videoUrl) {
        dispatch(setAlert("Post cannot be empty", "error"));
        return;
      }

      let postData = {
        type: "text",
        text: postText.trim(),
      };

      if (images.length > 0) {
        postData.type = "media";
        for (let i = 0; i < images.length; i++) {
          const image = images[i];

          if (image.cropped) {
            const croppedImage = await getCroppedImg(image.previewUrl, image.cropped.croppedAreaPixels);

            //Convert dataURL to Blob
            const response = await fetch(croppedImage);
            const blob = await response.blob();
            formData.append("images", blob, `image-${i}.jpg`);
          }
        }
      } else if (videoUrl && videoUrl.trim() !== "") {
        if (videoUrl.trim().length < 256) {
          postData.type = "video";
          postData.videoUrl = videoUrl;
        } else {
          dispatch(setAlert("Video URL is too long", "error"));
          return;
        }
      }

      //Append recipe data as JSON object
      formData.append("post", JSON.stringify(postData));

      // Send the form data to your backend route
      try {
        const response = await api.post("/post/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        dispatch(setAlert("Post created", "success"));
        return true;
      } catch (error) {
        // Handle the error
        console.error(error);

        dispatch(setAlert("Error creating post. Refresh and try again.", "error"));
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export const setPostInteractions = (postid, interactions) => async (dispatch) => {
  dispatch({
    type: SET_POST_INTERACTIONS,
    payload: {
      postid,
      interactions,
    },
  });
};

export const deleteComment = (postid, commentid) => async (dispatch) => {
  //TO DO
  //Send delete request
  try {
    const res = await api.delete(`/post/comment/${postid}/${commentid}`);

    dispatch({
      type: FEED_DELETE_COMMENT,
      payload: {
        postid,
        commentid,
      },
    });

    dispatch(getCommentsForPost(postid));
  } catch (error) {
    console.log(error);
    dispatch(setAlert("Could not delete comment. Please refresh the page and try again", "error"));
  }
};
