import { useEffect } from "react";

export const useModalScrollLock = (lock) => {
  useEffect(() => {
    if (lock) {
      // Save current scroll position
      const scrollY = window.scrollY;

      // Add classes to body for scroll lock
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY}px`;
      document.body.style.left = "0";
      document.body.style.right = "0";
      document.body.style.width = "100%";

      // Add Tailwind overflow class
      document.body.classList.add("overflow-hidden");

      return () => {
        // Remove styles and classes
        document.body.style.position = "";
        document.body.style.top = "";
        document.body.style.left = "";
        document.body.style.right = "";
        document.body.style.width = "";
        document.body.classList.remove("overflow-hidden");

        // Restore scroll position
        window.scrollTo(0, scrollY);
      };
    }
  }, [lock]);
};
