import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const theme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiPickersModal: {
        // Override styles for the modal dialog
        dialogRoot: {
          backgroundColor: "white",
        },
      },
      MuiMenu: {
        // Override styles for the modal dialog
        paper: {
          backgroundColor: "white",
        },
      },
      MuiButton: {
        root: {
          textTransform: "none",
          fontWeight: 400,
          color: "#362e2d",
          borderRadius: "0.375rem",
        },
        contained: {
          color: "#ffffff",
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: "0.5rem",
          backgroundColor: "white",
        },
      },
      // ... add more component overrides as needed
    },
    spacing: 8,
    shape: {
      borderRadius: 10,
    },
    palette: {
      default: {
        main: grey[300],
        dark: grey[400],
      },
      primary: {
        main: "#598a56",
      },
      secondary: {
        main: "#F2F1F1", //10% secondaryText
      },
      secondaryText: {
        main: "#7a726e",
      },
      hover: {
        main: "#dadada",
      },
      warning: {
        //main: "#dc3545",
        main: "#ff9800",
      },
      danger: {
        main: "#dc3545",
      },
      success: {
        main: "#598a56",
      },
      info: {
        main: "#3674c7",
      },
      separator: "#362e2d26", //15% text on white
      tertiaryTextOnWhite: "#362e2d26",
      mainGradient: "linear-gradient(145deg, #598a56 0%, #507c4d 100%)",
      white: "#ffffff",
      background: "#ffffff",
      secondaryGrey: "#f5f6f7",
      mainGreen: "#598a56",
      textOnWhite: "#362e2d",
      secondaryTextOnWhite: "#7a726e",
      rating: "#FFD700",
    },
    typography: {
      fontFamily: '"Poppins", sans-serif',
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 480,
        md: 768,
        lg: 976,
        v5xl: 1536,
        xl: 1440,
      },
    },
  })
);

export default theme;
