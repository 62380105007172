import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Backdrop, Fade, Paper, Typography, TextField, Button, IconButton } from "@mui/material";

//FA Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

//Actions
import {
  editSectionStep,
  removeStep,
  setCreateRecipeErrorByPath,
  clearCreateRecipeErrorByPath,
} from "../../../../actions/createrecipe";
import { setAlert } from "../../../../actions/alert";

import { ReactComponent as TrashIcon } from "../../../../assets/icons/svg/trash.svg";
import { ReactComponent as TrashCheckIcon } from "../../../../assets/icons/svg/trash-check.svg";

//TO DO:
//Push edits to redux state
//Remove step
//Add tip to step
const EditDirectionModal = ({
  step,
  stepIndex,
  sectionIndex,
  sections,
  handleClose,
  editSectionStep,
  removeStep,
  setAlert,
  errors,
  setCreateRecipeErrorByPath,
  clearCreateRecipeErrorByPath,
}) => {
  const [text, setText] = useState(step.text);

  const onTextChange = (e) => {
    if (e.target.value.length > 1024) {
      setCreateRecipeErrorByPath(`sections[${sectionIndex}].steps[${stepIndex}].text`, [
        { msg: "Step text cannot be longer than 1024 characters" },
      ]);
    } else {
      setText(e.target.value);
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.steps.${stepIndex}.text`);
    }
  };

  const [tip, setTip] = useState(
    sections &&
      sections.length > 0 &&
      sections[sectionIndex].steps &&
      sections[sectionIndex].steps[stepIndex] &&
      sections[sectionIndex].steps[stepIndex].tips &&
      sections[sectionIndex].steps[stepIndex].tips.length > 0
      ? sections[sectionIndex].steps[stepIndex].tips[0]
      : ""
  );

  const onTipChange = (e) => {
    if (e.target.value.length > 256) {
      setCreateRecipeErrorByPath(`sections[${sectionIndex}].steps[${stepIndex}].tips`, [
        { msg: "Tip cannot be longer than 256 characters" },
      ]);
    } else {
      setTip(e.target.value);
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.steps.${stepIndex}.tips`);
    }
  };

  const [showTip, setShowTip] = useState(
    sections &&
      sections.length > 0 &&
      sections[sectionIndex].steps &&
      sections[sectionIndex].steps[stepIndex] &&
      sections[sectionIndex].steps[stepIndex].tips &&
      sections[sectionIndex].steps[stepIndex].tips.length > 0
  );

  const handleRemoveTip = () => {
    setTip("");
    setShowTip(false);
  };

  const [removeAreYouSure, setRemoveAreYouSure] = useState(false);

  const handleSubmit = (e) => {
    //Stop higher forms from firing
    e.stopPropagation();
    e.preventDefault();

    editSectionStep(sectionIndex, stepIndex, { text, tip });
    handleClose();
    setAlert("Step updated", "success", 3000);
  };

  const handleRemoveStep = () => {
    removeStep(sectionIndex, stepIndex);
    handleClose();
    setAlert("Step removed", "success", 3000);
  };

  return (
    <div className="mx-4 mb-4">
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="flex flex-row items-center justify-between">
          <Typography variant="h6" className="font-normal">
            Edit Step
          </Typography>
          {removeAreYouSure ? (
            <Button variant="contained" color="secondary" onClick={() => handleRemoveStep()} disableElevation>
              <TrashCheckIcon className="h-4 w-4 fill-primaryText" />
            </Button>
          ) : (
            <Button variant="contained" color="secondary" onClick={() => setRemoveAreYouSure(true)} disableElevation>
              <TrashIcon className="h-4 w-4 fill-primaryText" />
            </Button>
          )}
        </div>
        <div className="mt-4">
          <TextField
            multiline
            fullWidth
            minRows={3}
            variant="outlined"
            label="Step"
            value={text}
            onChange={(e) => onTextChange(e)}
            error={!!errors?.sections?.[sectionIndex]?.steps?.[stepIndex]?.text}
            helperText={errors?.sections?.[sectionIndex]?.steps?.[stepIndex]?.text
              ?.map((error) => error.msg)
              .join(", ")}
          ></TextField>
        </div>
        <div className="mt-2 mb-4">
          {showTip ? (
            <Button onClick={() => handleRemoveTip()} disableRipple className="text-primaryText hover:bg-transparent">
              <Typography variant="subtitle2" className="font-normal text-xs">
                - Remove Tip
              </Typography>
            </Button>
          ) : (
            <Button onClick={() => setShowTip(true)} disableRipple className="text-primaryText hover:bg-transparent">
              <Typography variant="subtitle2" className="font-normal text-xs">
                + Add a tip for this step
              </Typography>
            </Button>
          )}
          {showTip && (
            <TextField
              multiline
              fullWidth
              variant="outlined"
              label="Tip"
              value={tip}
              onChange={(e) => onTipChange(e)}
              className="mt-2"
              error={!!errors?.sections?.[sectionIndex]?.steps?.[stepIndex]?.tips}
              helperText={errors?.sections?.[sectionIndex]?.steps?.[stepIndex]?.tips
                ?.map((error) => error.msg)
                .join(", ")}
            ></TextField>
          )}
        </div>
        <div className="flex justify-between">
          <Button variant="contained" color="primary" disableElevation type="submit" fullWidth>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

EditDirectionModal.propTypes = {
  step: PropTypes.object.isRequired,
  stepIndex: PropTypes.number.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  editSectionStep: PropTypes.func.isRequired,
  removeStep: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  sections: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  setCreateRecipeErrorByPath: PropTypes.func.isRequired,
  clearCreateRecipeErrorByPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  create: state.recipe.create,
  sections: state.recipe.create.sections,
  errors: state?.recipe?.create?.errors,
});

export default connect(mapStateToProps, {
  editSectionStep,
  removeStep,
  setAlert,
  setCreateRecipeErrorByPath,
  clearCreateRecipeErrorByPath,
})(EditDirectionModal);
