import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  createSection,
  fetchExternalRecipe,
  fetchGeneratedRecipe,
  setCreateRecipeErrorByPath,
  clearCreateRecipeErrorByPath,
} from "../../../../actions/createrecipe";
import { setAlert } from "../../../../actions/alert";

import { Typography, TextField, ButtonGroup, Button } from "@mui/material";
import SelectableButtonGroup from "../../../layout/SelectableButtonGroup";
import Spinner from "../../../layout/Spinner";

import { Tabs, TabsList, TabsTrigger } from "../../../ui/tabs";
import { togglePlusUpgradeModal } from "../../../../actions/plus";
import { setOriginalImages } from "../../../../actions/media";

//Tab icons
import { ReactComponent as PlusIcon } from "../../../../assets/icons/svg/plus.svg";
import { ReactComponent as ImportIcon } from "../../../../assets/icons/svg/import.svg";
import { ReactComponent as MagicWandIcon } from "../../../../assets/icons/svg/magic-wand-solid.svg";

//TO DO:
//Verify section title length
//Verify section title uniqueness
//Verify section title is not empty
//Verify section before is not empty (if not first section), don't even show modal if preceding section is empty

//Future:
//Search other recipes, add as a section(s) -> debounce and search w/ 1s delay?
//Set "hasSearched" to expand modal?
//Generate section from prompt

//TO DO FIRST THING
//Make buttons from addType
//Search recipes to add
//Source type -> generated/imported from/inspired by/[LINK TO OTHER RECIPE eg, from Casserole by @username]...
//For search/import, detect if text is url, change type based on that. Search opens up to cards that open to modals, action = "Add as section(s)"
//Search modal also comes up from bottom? Eg, showing search results, maybe new search box?
const AddRecipeSectionModal = ({
  setAlert,
  createSection,
  handleModalClose,
  fetchExternalRecipe,
  fetchGeneratedRecipe,
  setRecipeFields,
  initialAddType = "add",
  initialAddPrompt = "",
  plus,
  togglePlusUpgradeModal,
  setOriginalImages,
}) => {
  const [title, setTitle] = useState(initialAddPrompt ? initialAddPrompt : "");
  const [importLoading, setImportLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [addType, setAddType] = useState(initialAddType);

  const [label, setLabel] = useState("Section Title");
  const [helperText, setHelperText] = useState("Add a title for a new recipe section");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (addType === "add") {
      handleAddSection();
      return;
    }

    if (addType === "generate") {
      handleGenerateRecipe();
      return;
    }

    if (addType === "import") {
      handleExternalRecipe();
      return;
    }
  };

  const handleAddSection = (e) => {
    if (title === "") {
      setAlert("Section title cannot be empty", "warning");
      return;
    }

    if (title.length > 50) {
      setAlert("Section title cannot be longer than 50 characters", "warning");
      return;
    }

    let added = createSection(title);

    if (added) {
      setTitle("");
      handleModalClose();
    }
  };

  const handleTitleChange = (e) => {
    let title = e.target.value;

    /*
    if (title.length > 50) {
      return;
    }*/
    setTitle(e.target.value);
  };

  //TODO: Dispatch reporting action
  const handleGenerateRecipe = async () => {
    if (plus.isPlus) {
      if (title.trim() === "") {
        setAlert("Describe the recipe you want to generate", "warning");
        return;
      }

      if (title.length > 2048) {
        setAlert("Prompt must be less than 2048 characters", "warning");
        return;
      }

      setGenerateLoading(true);
      const recipeData = await fetchGeneratedRecipe(title);

      if (recipeData.error) {
        //Rate limit
        if (recipeData.error.code && recipeData.error.code === 429) {
          setAlert(
            "You're cooking too hot right now! Let the kitchen cool down, and try again in a few minutes.",
            "error"
          );
        } else {
          setAlert("Could not generate recipe. Please try again.", "error");
        }
        setGenerateLoading(false);
      } else {
        setRecipeFields(recipeData);

        setAlert(recipeData.name ? `Generated ${recipeData.name}` : "Generated Recipe", "success");

        handleModalClose();
      }
    } else {
      togglePlusUpgradeModal(true);
    }
  };

  const handleExternalRecipe = async () => {
    setImportLoading(true);

    const recipeData = await fetchExternalRecipe(title);

    if (recipeData.error) {
      if (recipeData.error === 429) {
        setAlert(recipeData.msg, "error");
      } else if (recipeData.error === 404) {
        setAlert(recipeData.msg, "warning");
      } else if (recipeData.error === 408) {
        setAlert("The recipe you're trying to import is taking to long to load. Please try again.", "error");
      } else if (recipeData.error === 503) {
        setAlert(
          "Recipe importing is currently unavailable. Please refresh the page and try again. If the issue persists, please contact support.",
          "error"
        );
      } else {
        setAlert(
          "An error occurred while importing the recipe. Please try again. If the issue persists, please contact support.",
          "error"
        );
      }
      setImportLoading(false);
    } else {
      setRecipeFields(recipeData);

      if (recipeData.image && recipeData.image?.length > 0) {
        setOriginalImages(recipeData.image);
      }

      setAlert(recipeData.name ? `Imported ${recipeData.name}` : "Imported Recipe", "success");

      //Notify user to check ingredients
      if (recipeData.sections && recipeData.sections.length > 1 && recipeData.sections[0].ingredients.length > 0) {
        setAlert(
          "Heads up! The ingredients in this recipe weren't sorted into sections on the site. We’ve placed them all in the first section for you. Please quickly organize them by moving them into their correct sections.",
          "warning",
          20000
        );
      }
      setImportLoading(false);
      handleModalClose();
    }
  };

  const handleSelectSearchImport = () => {
    setAddType("import");
    setHelperText("Import a recipe from a URL");
    setLabel("Import Recipe");
    //Pass text in textbox to other modal
    //Open search modal
    //
  };

  const handleSelectAddSection = () => {
    setAddType("add");
    setHelperText("Add a title for a new recipe section");
    setLabel("Section Title");
  };

  const handleSelectGenerate = () => {
    setAddType("generate");
    setHelperText("Generate a recipe from a prompt describing the recipe you want");
    setLabel("Generate Recipe");
  };

  const handleTabChange = (value) => {
    if (value === "import") {
      handleSelectSearchImport();
    }

    if (value === "add") {
      handleSelectAddSection();
    }

    if (value === "generate") {
      handleSelectGenerate();
    }
  };

  useEffect(() => {
    switch (initialAddType) {
      case "add": {
        handleSelectAddSection();
        return;
      }
      case "import": {
        handleSelectSearchImport();
        return;
      }
      case "generate": {
        handleSelectGenerate();
        return;
      }
    }
  }, [initialAddType]);

  const [loadingMessage, setLoadingMessage] = useState("Preheating the oven...");

  const cookingTerms = [
    "Preheating the oven...",
    "Gathering ingredients...",
    "Mixing the batter...",
    "Greasing the pan...",
    "Folding in the flour...",
    "Whisking the eggs...",
    "Melting the butter...",
    "Sifting the dry ingredients...",
    "Measuring the spices...",
    "Lining the baking sheet...",
    "Preparing the filling...",
    "Kneading the dough...",
    "Letting it rise...",
    "Prepping the frosting...",
    "Almost ready to bake...",
  ];

  useEffect(() => {
    if (importLoading || generateLoading) {
      let index = 0;
      const interval = setInterval(() => {
        setLoadingMessage(cookingTerms[index]);
        index = (index + 1) % cookingTerms.length;
      }, 2500);
      return () => clearInterval(interval);
    }
  }, [importLoading, generateLoading]);

  return (
    <div className="mx-4 mb-4">
      {importLoading || generateLoading ? (
        <div className="flex flex-col items-center mb-8">
          <Spinner />
          <div>
            <Typography variant="subtitle1" className="text-secondaryText">
              {loadingMessage}
            </Typography>
          </div>
        </div>
      ) : (
        <div>
          <div className="mb-4">
            <div className="w-full">
              <Tabs value={addType} onValueChange={(value) => handleTabChange(value)} className="w-full">
                <TabsList className="w-full border border-separator p-0">
                  <TabsTrigger value="add" className="w-full h-full">
                    <span className="font-medium hidden sm:inline">+</span>&nbsp;Add
                  </TabsTrigger>
                  <TabsTrigger value="import" className="w-full h-full">
                    <ImportIcon
                      className={`w-4 h-4 mr-2 hidden sm:inline ${
                        addType === "import" ? "fill-primaryText" : "fill-primaryText/80"
                      }`}
                    />
                    Import
                  </TabsTrigger>
                  <TabsTrigger value="generate" className="w-full h-full">
                    <MagicWandIcon
                      className={`w-4 h-4 mr-2 hidden sm:inline ${
                        addType === "generate" ? "fill-primaryText" : "fill-primaryText/80"
                      }`}
                    />
                    Generate
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
          </div>

          <form onSubmit={(e) => handleSubmit(e)} className="w-full">
            <TextField
              id="section-title"
              label={label}
              variant="outlined"
              fullWidth
              onChange={(e) => handleTitleChange(e)}
              value={title}
              autoComplete="off"
              helperText={helperText}
              multiline={addType === "generate"}
              maxRows={10}
              inputProps={{ maxLength: addType !== "add" ? 2048 : 50 }}
            />
            <div className="w-full flex justify-end mt-4">
              <div className="w-full">
                {addType === "add" && (
                  <Button
                    variant="contained"
                    disableRipple
                    disableElevation
                    onClick={(e) => handleAddSection(e)}
                    fullWidth
                  >
                    Add Section
                  </Button>
                )}
                {addType === "import" && (
                  <Button
                    variant="contained"
                    disableRipple
                    disableElevation
                    onClick={() => handleExternalRecipe()}
                    fullWidth
                  >
                    Import
                  </Button>
                )}
                {addType === "generate" && (
                  <Button
                    variant="contained"
                    disableRipple
                    disableElevation
                    onClick={() => handleGenerateRecipe()}
                    fullWidth
                  >
                    Generate
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

AddRecipeSectionModal.propTypes = {
  createSection: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  fetchExternalRecipe: PropTypes.func.isRequired,
  fetchGeneratedRecipe: PropTypes.func.isRequired,
  plus: PropTypes.object.isRequired,
  togglePlusUpgradeModal: PropTypes.func.isRequired,
  setOriginalImages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  plus: state.plus,
});

export default connect(mapStateToProps, {
  createSection,
  setAlert,
  fetchExternalRecipe,
  fetchGeneratedRecipe,
  togglePlusUpgradeModal,
  setOriginalImages,
})(AddRecipeSectionModal);
