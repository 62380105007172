import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, TextField, Button, Checkbox, IconButton, Divider } from "@mui/material";

//Actions
import {
  editIngredient,
  removeIngredient,
  clearCreateRecipeErrorByPath,
  setCreateRecipeErrorByPath,
} from "../../../../actions/createrecipe";

import { setAlert } from "../../../../actions/alert";

//Modals
import IngredientInfoModal from "./IngredientInfoModal";
import CustomModal from "../../../modal/CustomModal";
import MoveIngredientModal from "./MoveIngredientModal";
//Icons
import { ReactComponent as InfoIcon } from "../../../../assets/icons/svg/info.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/svg/trash.svg";
import { ReactComponent as TrashCheckIcon } from "../../../../assets/icons/svg/trash-check.svg";
import { ReactComponent as MoveIcon } from "../../../../assets/icons/svg/arrow-down-to-right.svg";

import Searchable from "../../../layout/searchable/Searchable";

const EditIngredientModal = ({
  ingredient,
  sectionIndex,
  ingredientIndex,
  editIngredient,
  handleModalClose,
  removeIngredient,
  sections,
  errors,
  clearCreateRecipeErrorByPath,
  setCreateRecipeErrorByPath,
  onModalMove,
  setAlert,
}) => {
  const [ingredientState, setIngredientState] = useState({
    id: ingredient && ingredient.id ? ingredient.id : "",
    name: ingredient && ingredient.name ? { value: ingredient.name, name: ingredient.name } : [],
    quantity: ingredient && ingredient.quantity ? ingredient.quantity : "",
    unit: ingredient && ingredient.unit ? { value: ingredient.unit, name: ingredient.unit } : [],
    state: ingredient && ingredient.state ? ingredient.state : "",
    note: ingredient && ingredient.note ? ingredient.note : "",
    measured:
      ingredient && ingredient.measured
        ? ingredient.measured
        : ingredient.measured === false
        ? false
        : (ingredient.unit !== "not_exact" || ingredient.unit !== "to_taste") && ingredient.quantity !== ""
        ? true
        : false,
  });

  const [showOptionalFields, setShowOptionalFields] = useState(false);

  const [showMeasuredInfoModal, setShowMeasuredInfoModal] = useState(false);

  const [showMoveModal, setShowMoveModal] = useState(false);

  const handleModalMoveCleanup = () => {
    onModalMove();
    setShowMoveModal(false);
  };

  const handleHideOptionals = () => {
    //Clear optionals state
    setIngredientState({ ...ingredientState, state: "", note: "" });

    setShowOptionalFields(false);
  };

  //Remove state TO DO: Reset to false if clicking away from button
  const [removeAreYouSure, setRemoveAreYouSure] = useState(false);

  //Submit
  const handleSubmit = (e) => {
    //Stop higher forms from firing
    e.stopPropagation();
    e.preventDefault();

    clearCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}`);

    //Convert searchable values to strings for backend handoff
    const convertedState = { ...ingredientState };

    convertedState.name = convertedState?.name?.value !== "" ? convertedState.name.value : "";
    convertedState.unit = convertedState?.unit?.value !== "" ? convertedState.unit.value : "";

    //Verify values
    let hasErrors = false;

    const amountVerification = verifyQuantity(convertedState.quantity);
    const unitVerification = verifyUnit(convertedState.unit);
    const nameVerification = verifyName(convertedState.name);
    const stateVerification = verifyState(convertedState.state);
    const noteVerification = verifyNote(convertedState.note);

    hasErrors =
      amountVerification.hasErrors ||
      unitVerification.hasErrors ||
      nameVerification.hasErrors ||
      stateVerification.hasErrors ||
      noteVerification.hasErrors;

    //Final check to see if ingredient is measured and has an amount
    if (convertedState.measured && convertedState.quantity === "") {
      hasErrors = true;
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.amount`, [
        { msg: "Measured ingredients must have an amount" },
      ]);
    }

    if (!hasErrors) {
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}`);
      editIngredient(sectionIndex, ingredientIndex, convertedState);

      handleModalClose();
    } else {
      setAlert("Please fix the errors before saving", "error");
    }
  };

  const handleRemove = () => {
    //Remove ingredient
    removeIngredient(sectionIndex, ingredientIndex);
    handleModalClose();
  };

  //Verification handlers
  const verifyQuantity = (value) => {
    let hasErrors = false;
    let preventSetValue = false;
    const fractionRegex = /^(\d{1,3}\/\d{1,3})$|^(\d{1,3}\/)$/;

    if (fractionRegex.test(value)) {
      return { hasErrors: false, preventSetValue: false };
    }

    if (value === "" && ingredientState?.measured) {
      if (ingredientState?.unit?.value && ingredientState?.unit?.value !== "") {
        hasErrors = true;
        setCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.amount`, [
          { msg: "A measured ingredient with a unit must have an amount" },
        ]);
      }
    }

    if (isNaN(value)) {
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.amount`, [
        { msg: "Amount must be a number or basic fraction" },
      ]);
      hasErrors = true;
    } else if (value < 0.001 && value !== "") {
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.amount`, [
        { msg: "Amount must be greater than or equal to 0.001" },
      ]);
      hasErrors = true;
    } else if (value > 10000) {
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.amount`, [
        { msg: "Amount must be less than or equal to 10,000" },
      ]);
      preventSetValue = true;
      hasErrors = true;
    }

    if (!hasErrors) {
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.amount`);
    }
    return { hasErrors, preventSetValue };
  };

  const verifyUnit = (value) => {
    //Check value requirements (string lengths, etc)
    let hasErrors = false;
    let preventSetValue = false;

    if (value) {
      if (value.length > 32) {
        hasErrors = true;
        preventSetValue = true;
        setCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.unit`, [
          { msg: "Unit must be less than 32 characters" },
        ]);
      }

      if (
        (typeof ingredientState?.quantity === "string" && ingredientState?.quantity?.trim() === "") ||
        ingredientState?.quantity === null
      ) {
        if (value !== "" && ingredientState.measured) {
          hasErrors = true;
          setCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.amount`, [
            { msg: "An ingredient with a unit must have an amount" },
          ]);
        }
      }
    }

    //Check required field parings, eg unit needs non-zero, non-empty quantity
    return {
      hasErrors,
      preventSetValue,
    };
  };

  const verifyName = (value) => {
    let hasErrors = false;
    let preventSetValue = false;

    if (value) {
      if (value.length === 0) {
        hasErrors = true;
        setCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.name`, [
          { msg: "Ingredient name cannot be empty" },
        ]);
      }

      if (value.length > 64) {
        hasErrors = true;
        preventSetValue = true;
      }

      if (!hasErrors) {
        clearCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.name`);
      }
    } else {
      hasErrors = true;
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.name`, [
        { msg: "Ingredient name cannot be empty" },
      ]);
    }
    return {
      hasErrors,
      preventSetValue,
    };
  };

  const verifyState = (value) => {
    let hasErrors = false;
    let preventSetValue = false;

    if (value.length > 32) {
      hasErrors = true;
      preventSetValue = true;
    }

    return { hasErrors, preventSetValue };
  };

  const verifyNote = (value) => {
    let hasErrors = false;
    let preventSetValue = false;

    if (value.length > 128) {
      hasErrors = true;
      preventSetValue = true;
    }

    return { hasErrors, preventSetValue };
  };

  //Input handlers
  const handleQuantityChange = (e) => {
    const value = e.target.value;
    const { hasErrors, preventSetValue } = verifyQuantity(value);

    if (!hasErrors) {
      if (errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.quantity) {
        clearCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.quantity`);
      }
    }

    if (!preventSetValue) {
      setIngredientState({ ...ingredientState, quantity: e.target.value });
    }
  };

  const handleUnitChange = (searchableData) => {
    if (searchableData === null || searchableData?.value?.length === 0) {
      setIngredientState({ ...ingredientState, unit: [] });
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.unit`);
      if (
        errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.amount?.[0]?.msg ===
        "A measured ingredient with a unit must have an amount"
      ) {
        clearCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.amount`);
      }
      return;
    }

    const { hasErrors, preventSetValue } = verifyUnit(searchableData.value);

    if (!hasErrors) {
      if (errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.unit) {
        clearCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.unit`);
      }
    }

    if (!preventSetValue) {
      setIngredientState({ ...ingredientState, unit: searchableData });
    }
  };

  const handleNameChange = (searchableData) => {
    if (searchableData === null || searchableData?.value?.length === 0) {
      setIngredientState({ ...ingredientState, name: [] });
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.name`);
      return;
    }

    const { hasErrors, preventSetValue } = verifyName(searchableData.value);

    if (!hasErrors) {
      if (errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.name) {
        clearCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.name`);
      }
    }

    if (!preventSetValue) {
      setIngredientState({ ...ingredientState, name: searchableData });
    }
  };

  const handleStateChange = (e) => {
    const value = e.target.value;

    const { hasErrors, preventSetValue } = verifyState(value);

    if (!hasErrors) {
      if (errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.state) {
        clearCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.state`);
      }
    }

    if (!preventSetValue) {
      setIngredientState({ ...ingredientState, state: value });
    }
  };

  const handleNoteChange = (e) => {
    const value = e.target.value;

    const { hasErrors, preventSetValue } = verifyNote(value);

    if (!hasErrors) {
      if (errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.note) {
        clearCreateRecipeErrorByPath(`sections.${sectionIndex}.ingredients.${ingredientIndex}.note`);
      }
    }

    if (!preventSetValue) {
      setIngredientState({ ...ingredientState, note: value });
    }
  };

  const generateHelperTextForUnit = () => {
    if (ingredientState.unit === "not_exact") {
      return "This ingredient is not measured in exact quantities";
    } else if (ingredientState.unit === "to_taste") {
      return "This ingredient is measured to taste";
    } else if (ingredientState.unit === "self") {
      return "This ingredient is measured by itself. 1 egg, 2 apples";
    } else if (ingredientState.unit === "count") {
      return "This ingredient is measured by count. 2 eggs, 3 apples";
    } else {
      return "";
    }
  };

  //Check to display optional fields on modal open
  useEffect(() => {
    if (ingredient.state !== "" || ingredient.note !== "") {
      setShowOptionalFields(true);
    }
  }, []);

  return (
    <div className="mx-4 mb-4">
      <CustomModal
        open={showMeasuredInfoModal}
        handleClose={() => setShowMeasuredInfoModal(false)}
        type="bottom"
        parentClassNameOverride="md:max-w-[600px] lg:max-w-[700px] xl:max-w-[800px]"
      >
        <IngredientInfoModal initialViewType="ingredients" />
      </CustomModal>
      <CustomModal open={showMoveModal} handleClose={() => setShowMoveModal(false)} type="bottom">
        <MoveIngredientModal
          sectionIndex={sectionIndex}
          ingredientIndex={ingredientIndex}
          handleClose={() => setShowMoveModal(false)}
          modalMoveCleanup={handleModalMoveCleanup}
        />
      </CustomModal>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="flex flex-col md:flex-row justify-between md:items-center">
          <div className="flex flex-col w-full">
            <Typography variant="h6" className="font-normal">
              Edit Ingredient
            </Typography>
            <div className="flex flex-row items-center justify-between w-full my-2 gap-2">
              <div className="flex flex-row items-center gap-2">
                {removeAreYouSure ? (
                  <Button variant="contained" color="secondary" onClick={() => handleRemove()} disableElevation>
                    <TrashCheckIcon className="h-4 w-4 fill-primaryText" />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setRemoveAreYouSure(true)}
                    disableElevation
                  >
                    <TrashIcon className="h-4 w-4 fill-primaryText" />
                  </Button>
                )}
                {sections.length > 1 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setShowMoveModal(true)}
                    className="border-secondaryText-200 hover:border-primaryText"
                    disableElevation
                  >
                    <div className="flex flex-row items-center gap-2">
                      <MoveIcon className="h-3 w-3 fill-primaryText" />
                      <Typography variant="subtitle2" className="font-normal text-xs text-primaryText">
                        Move
                      </Typography>
                    </div>
                  </Button>
                )}
              </div>
              {!!errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex] ? (
                <Button
                  onClick={() => setShowMeasuredInfoModal(true)}
                  disableRipple
                  variant="contained"
                  color="primary"
                  disableElevation
                  className="flex flex-row items-center gap-1"
                >
                  <Typography variant="caption" className="text-background font-medium text-xs">
                    Help
                  </Typography>
                  <InfoIcon className="h-3 w-3 fill-background" />
                </Button>
              ) : (
                <Button
                  onClick={() => setShowMeasuredInfoModal(true)}
                  disableRipple
                  variant="contained"
                  color="secondary"
                  disableElevation
                  className="flex flex-row items-center gap-1"
                >
                  <Typography variant="caption" className="text-primaryText text-xs">
                    Help
                  </Typography>
                  <InfoIcon className="h-3 w-3 fill-primaryText" />
                </Button>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row gap-2 my-4">
            <div className="w-1/2">
              <TextField
                label="Amount"
                variant="outlined"
                fullWidth
                value={ingredientState.quantity}
                onChange={(e) => handleQuantityChange(e)}
                error={!!errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.amount}
                helperText={errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.amount
                  ?.map((error) => error.msg)
                  .join(", ")}
                inputProps={{ maxLength: 7 }}
              />
            </div>

            <div className="w-1/2">
              <Searchable
                label="Unit"
                freeSolo={true}
                apiUrl="/units/searchable"
                allowAdd={true}
                multiple={false}
                onItemChange={handleUnitChange}
                maxInputLength={32}
                fullWidth
                selectedItemsFromParent={ingredientState.unit}
                error={!!errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.unit}
                helperText={
                  errors
                    ? errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.unit
                        ?.map((error) => error.msg)
                        .join(", ")
                    : generateHelperTextForUnit()
                }
              />
            </div>
          </div>

          <Searchable
            label="Ingredient Name"
            freeSolo={true}
            apiUrl="/ingredients/searchable"
            maxInputLength={64}
            allowAdd={true}
            multiple={false}
            onItemChange={handleNameChange}
            selectedItemsFromParent={ingredientState.name}
            convertToTitleCaseOnLoad={true}
            error={!!errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.name}
            helperText={
              errors
                ? errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.name
                    ?.map((error) => error.msg)
                    .join(", ")
                : null
            }
          />
          <div className="flex flex-row items-center py-2">
            <Checkbox
              className="p-0 mr-1 hover:bg-transparent"
              color="primary"
              checked={ingredientState.measured}
              disableRipple
              onClick={() => setIngredientState({ ...ingredientState, measured: !ingredientState.measured })}
              size="small"
            />
            <Typography variant="subtitle2" className="text-secondaryText-700 font-normal text-xs">
              Measured Ingredient
            </Typography>
          </div>
          <div className="pb-4">
            {showOptionalFields ? (
              <Button onClick={() => handleHideOptionals()} disableRipple className="hover:bg-transparent">
                <Typography variant="subtitle2" className="text-secondaryText-600 font-normal text-xs">
                  - Hide Notes/Ingredient State
                </Typography>
              </Button>
            ) : (
              <Button onClick={() => setShowOptionalFields(true)} disableRipple className="hover:bg-transparent">
                <Typography variant="subtitle2" className="text-secondaryText-600 font-normal text-xs">
                  + Add Notes/Ingredient State
                </Typography>
              </Button>
            )}
            {showOptionalFields && (
              <div className="mt-2">
                <TextField
                  label="State"
                  variant="outlined"
                  fullWidth
                  value={ingredientState.state}
                  onChange={(e) => handleStateChange(e)}
                  error={!!errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.state}
                  helperText={
                    errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.state
                      ? errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.state
                          ?.map((error) => error.msg)
                          .join(", ")
                      : "Fresh, Diced, Minced, etc."
                  }
                  inputProps={{ maxLength: 32 }}
                />
                <TextField
                  label="Notes"
                  variant="outlined"
                  className="mt-2"
                  fullWidth
                  value={ingredientState.note}
                  onChange={(e) => handleNoteChange(e)}
                  error={!!errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.note}
                  helperText={errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.note
                    ?.map((error) => error.msg)
                    .join(", ")}
                  inputProps={{ maxLength: 128 }}
                />
              </div>
            )}
          </div>
          <div className="flex flex-row justify-between mt-4">
            <Button variant="contained" color="primary" disableElevation type="submit" fullWidth>
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

EditIngredientModal.propTypes = {
  editIngredient: PropTypes.func.isRequired,
  ingredient: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  ingredientIndex: PropTypes.number.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  removeIngredient: PropTypes.func.isRequired,
  sections: PropTypes.array.isRequired,
  errors: PropTypes.object,
  clearCreateRecipeErrorByPath: PropTypes.func.isRequired,
  setCreateRecipeErrorByPath: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sections: state.recipe?.create?.sections,
  errors: state.recipe?.create?.errors,
});

export default connect(mapStateToProps, {
  editIngredient,
  removeIngredient,
  clearCreateRecipeErrorByPath,
  setCreateRecipeErrorByPath,
  setAlert,
})(EditIngredientModal);
