import { TrendingUpOutlined } from "@mui/icons-material";
import api from "../api/api";
import viper from "../api/viper";
import store, { history } from "../store";
import { setAlert } from "./alert";

// Redux types
import {
  RECIPE_CREATE_INGRED_ERROR,
  RECIPE_CREATE_UPDATE,
  RECIPE_CREATE_FORMAT_INGRED,
  GET_EDIT_RECIPE,
  GET_EXTERNAL_RECIPE,
  GET_GENERATED_RECIPE,
  RECIPE_CREATE_RESET,
  TOGGLE_RECIPE_MACROS_MODAL,
  TOGGLE_RECIPE_OUTPUT_MODAL,
  TOGGLE_RECIPE_TIMING_MODAL,
  SET_CREATE_RECIPE_ERRORS,
  RESET_CREATE_RECIPE_ERRORS,
  CLEAR_CREATE_RECIPE_ERROR_PATH,
} from "./types";

export const resetCreateRecipe = () => (dispatch) => {
  dispatch({
    type: RECIPE_CREATE_RESET,
  });
};

export const processIngredients = (ingredients, sectionIndex) => async (dispatch) => {
  try {
    if (ingredients.length === 0) {
      dispatch(setAlert("Ingredient cannot be empty", "warning"));
      return false;
    }

    dispatch({
      type: RECIPE_CREATE_FORMAT_INGRED,
    });

    const res = await api.post("/ingredients/process", { ingredients });

    const state = store.getState();
    const newSections = state.recipe.create.sections.map((section) => ({
      ...section,
      ingredients: [...section.ingredients],
    }));

    // Push the processed ingredients to the section
    if (res.data.ingredients.length > 0) {
      for (let i = 0; i < res.data.ingredients.length; i++) {
        let newIngredient = { ...res.data.ingredients[i] };

        if (!newIngredient.measured) {
          newIngredient.measured = !(
            newIngredient.quantity === 0 ||
            newIngredient.unit === "not_exact" ||
            newIngredient.unit === "to_taste"
          );
        }
        newSections[sectionIndex].ingredients.push(newIngredient);
      }
    } else {
      dispatch(setAlert("No ingredients detected", "warning"));
      return false;
    }

    dispatch({
      type: RECIPE_CREATE_UPDATE,
      payload: newSections,
    });
    return true;
  } catch (error) {
    const state = store.getState();
    const newSections = state.recipe.create.sections.map((section) => ({
      ...section,
      ingredients: [...section.ingredients],
    }));

    newSections[sectionIndex].ingredients.push({
      name: "Unknown Ingredient",
      quantity: "",
      unit: "",
      state: "",
      note: "",
      id: "",
      measured: true,
    });

    dispatch({
      type: RECIPE_CREATE_INGRED_ERROR,
      payload: newSections,
    });
    dispatch(setAlert("Error processing ingredient. Click the ingredient to add the info", "error"));
    return false;
  }
};

// Create section by title
export const createSection = (title) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
    steps: [...section.steps],
  }));

  // Check for title uniqueness
  for (let section of newSections) {
    if (section.title === title) {
      dispatch(setAlert("Section title must be unique", "warning"));
      return false;
    }
  }

  newSections.push({
    title,
    ingredients: [],
    steps: [],
    macros: [],
    servingSize: "",
    timing: {
      rest: {
        hours: "",
        minutes: "",
      },
      prep: {
        hours: "",
        minutes: "",
      },
      cook: {
        hours: "",
        minutes: "",
      },
      total: {
        hours: "",
        minutes: "",
      },
    },
    output: {
      servings: "",
      yield: {
        amount: "",
        unit: "",
        ingredient: "",
      },
    },
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });

  return true;
};

// Add ingredient to section
export const addIngredient = (sectionIndex, ingredient) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
  }));

  newSections[sectionIndex].ingredients.push(ingredient);

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Move ingredient in section to new index
export const moveIngredient = (sourceSectionIndex, destSectionIndex, oldIndex, newIndex) => (dispatch) => {
  const state = store.getState();

  // Clone sections for ingredient data
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
  }));

  // Clone errors structure
  const newErrors = state.recipe.create.errors
    ? JSON.parse(JSON.stringify(state.recipe.create.errors))
    : {
        info: {},
        sections: [],
        dietary: {},
      };

  // Move ingredient data
  const [removedIngredient] = newSections[sourceSectionIndex].ingredients.splice(oldIndex, 1);
  const insertIndex = newIndex === -1 ? newSections[destSectionIndex].ingredients.length : newIndex;
  newSections[destSectionIndex].ingredients.splice(insertIndex, 0, removedIngredient);

  // Handle error movement if errors exist
  if (newErrors?.sections?.[sourceSectionIndex]?.ingredients) {
    // Initialize destination section errors if needed
    if (!newErrors.sections[destSectionIndex]) {
      newErrors.sections[destSectionIndex] = { ingredients: [] };
    }
    if (!newErrors.sections[destSectionIndex].ingredients) {
      newErrors.sections[destSectionIndex].ingredients = [];
    }

    // Ensure error arrays are properly sized
    const sourceIngredientsLength = newSections[sourceSectionIndex].ingredients.length;
    const destIngredientsLength = newSections[destSectionIndex].ingredients.length;

    // Pad source error array if needed
    while (newErrors.sections[sourceSectionIndex].ingredients.length < sourceIngredientsLength) {
      newErrors.sections[sourceSectionIndex].ingredients.push(null);
    }

    // Pad destination error array if needed
    while (newErrors.sections[destSectionIndex].ingredients.length < destIngredientsLength) {
      newErrors.sections[destSectionIndex].ingredients.push(null);
    }

    // Move error object
    const removedError = newErrors.sections[sourceSectionIndex].ingredients[oldIndex];

    //Remove error from source
    newErrors.sections[sourceSectionIndex].ingredients.splice(oldIndex, 1);
    //Insert removed error at new index
    newErrors.sections[destSectionIndex].ingredients.splice(insertIndex, 0, removedError);

    // Trim error arrays to match final ingredient array lengths
    const finalSourceLength = newSections[sourceSectionIndex].ingredients.length;
    const finalDestLength = newSections[destSectionIndex].ingredients.length;

    newErrors.sections[sourceSectionIndex].ingredients = newErrors.sections[sourceSectionIndex].ingredients.slice(
      0,
      finalSourceLength
    );
    newErrors.sections[destSectionIndex].ingredients = newErrors.sections[destSectionIndex].ingredients.slice(
      0,
      finalDestLength
    );

    // Clean up empty error arrays
    if (newErrors.sections[sourceSectionIndex].ingredients.every((err) => err === null)) {
      delete newErrors.sections[sourceSectionIndex].ingredients;
    }
    if (Object.keys(newErrors.sections[sourceSectionIndex]).length === 0) {
      newErrors.sections[sourceSectionIndex] = null;
    }
  }

  // Dispatch updates for both sections and errors
  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });

  if (newErrors?.sections) {
    dispatch({
      type: SET_CREATE_RECIPE_ERRORS,
      payload: newErrors,
    });
  }
};

// Remove ingredient from section
export const removeIngredient = (sectionIndex, ingredientIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
  }));

  // Clone errors structure if it exists
  const newErrors = state.recipe.create.errors
    ? JSON.parse(JSON.stringify(state.recipe.create.errors))
    : {
        info: {},
        sections: [],
        dietary: {},
      };

  // Remove ingredient from section
  newSections[sectionIndex].ingredients.splice(ingredientIndex, 1);

  // Handle error removal if errors exist for this section
  if (newErrors?.sections?.[sectionIndex]?.ingredients) {
    // Remove the error at ingredientIndex
    newErrors.sections[sectionIndex].ingredients.splice(ingredientIndex, 1);

    // Clean up empty error arrays
    if (newErrors.sections[sectionIndex].ingredients.every((err) => err === null)) {
      delete newErrors.sections[sectionIndex].ingredients;
    }
    if (Object.keys(newErrors.sections[sectionIndex]).length === 0) {
      newErrors.sections[sectionIndex] = null;
    }
  }

  // Dispatch updates for both sections and errors
  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });

  if (newErrors?.sections) {
    dispatch({
      type: SET_CREATE_RECIPE_ERRORS,
      payload: newErrors,
    });
  }
};

// Edit ingredient in section
export const editIngredient = (sectionIndex, ingredientIndex, ingredient) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
  }));

  let trimmedIngredient = { ...ingredient };
  // Trim strings for all keys in ingredient
  for (let key in trimmedIngredient) {
    if (typeof trimmedIngredient[key] === "string") {
      trimmedIngredient[key] = trimmedIngredient[key].trim();
    }
  }

  // Set alert if invalid fields
  if (ingredient.name === "") {
    dispatch(setAlert("Name cannot be empty", "warning"));
    return {
      error: "empty_name",
    };
  }

  newSections[sectionIndex].ingredients[ingredientIndex] = trimmedIngredient;
  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
  return {
    error: false,
  };
};

// Add step to section
export const addStep = (sectionIndex, stepText) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    steps: [...section.steps],
  }));

  newSections[sectionIndex].steps.push({ text: stepText, tips: [] });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Edit step in section (including adding tip)
export const editSectionStep = (sectionIndex, stepIndex, data) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    steps: [...section.steps],
  }));

  newSections[sectionIndex].steps[stepIndex] = {
    text: data.text,
    tips: data.tip && data.tip !== "" ? [data.tip] : [],
  };

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Remove step from section
export const removeStep = (sectionIndex, stepIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    steps: [...section.steps],
  }));

  newSections[sectionIndex].steps.splice(stepIndex, 1);

  // Clone errors structure if it exists
  const newErrors = state.recipe.create.errors
    ? JSON.parse(JSON.stringify(state.recipe.create.errors))
    : {
        info: {},
        sections: [],
        dietary: {},
      };

  // Handle error removal if errors exist for this section
  if (newErrors?.sections?.[sectionIndex]?.steps) {
    // Remove the error at ingredientIndex
    newErrors.sections[sectionIndex].steps.splice(stepIndex, 1);

    // Clean up empty error arrays
    if (newErrors.sections[sectionIndex].steps.every((err) => err === null)) {
      delete newErrors.sections[sectionIndex].steps;
    }
    if (Object.keys(newErrors.sections[sectionIndex]).length === 0) {
      newErrors.sections[sectionIndex] = null;
    }
  }

  // Dispatch updates for both sections and errors
  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });

  if (newErrors?.sections) {
    dispatch({
      type: SET_CREATE_RECIPE_ERRORS,
      payload: newErrors,
    });
  }
};

// Edit section title
export const editSectionInfo = (sectionIndex, title, source) => (dispatch) => {
  const state = store.getState();

  // Check if the trimmed title already exists in another section
  const trimmedTitle = title.trim();

  if (trimmedTitle === "") {
    dispatch(setAlert("Section title cannot be empty", "warning"));
    dispatch(setCreateRecipeErrorByPath(`sections[${sectionIndex}].title`, [{ msg: "Title cannot be empty" }]));
    return null;
  }

  const isTitleDuplicate = state.recipe.create.sections.some(
    (section, index) => index !== sectionIndex && section.title === trimmedTitle
  );

  if (isTitleDuplicate) {
    // If the title is a duplicate, dispatch an alert action
    dispatch(
      setCreateRecipeErrorByPath(`sections[${sectionIndex}].title`, [
        { msg: "Section title already exists. Please choose a unique title." },
      ])
    );
    return null; // Exit the function early
  }

  let verifiedSource = source;

  //If source is automatically generated, do not allow editing
  if (state.recipe.create.sections[sectionIndex]?.source?.ref) {
    verifiedSource = state.recipe.create.sections[sectionIndex].source;
  } else {
    let allowedTypes = [
      "Inspired by",
      "Adapted from",
      "Based on",
      "A take on",
      "Found in",
      "Handed down from",
      "Influenced by",
      "Generated",
      "Generated with",
      "Imported from",
    ];

    if (source) {
      if (source.type === "Add a source") {
        verifiedSource = null;
      } else {
        //Verify source type is one of the allowed types
        if (!allowedTypes.includes(source.type)) {
          verifiedSource = null;
          dispatch(setAlert("Invalid source type", "warning"));
          dispatch(
            setCreateRecipeErrorByPath(`sections[${sectionIndex}].source.type`, [{ msg: "Invalid source type" }])
          );
          return null;
        }

        //Verify source at least source OR url
        if (source.text === "" && source.url === "") {
          verifiedSource = null;
          dispatch(setAlert("Source must have a source or URL", "warning"));
          dispatch(
            setCreateRecipeErrorByPath(`sections[${sectionIndex}].source.text`, [
              { msg: "Source must have a source or URL" },
            ])
          );
          return null;
        } else {
          //Verify text and url are both under char limits
          source.text = source.text.trim();
          source.url = source.url.trim();

          if (source.text.length > 128) {
            dispatch(setAlert("Source text cannot be longer than 128 characters", "warning"));
            dispatch(
              setCreateRecipeErrorByPath(`sections[${sectionIndex}].source.text`, [
                { msg: "Source text cannot be longer than 128 characters" },
              ])
            );
            return null;
          }
          if (source.url.length > 256) {
            dispatch(setAlert("Source URL cannot be longer than 256 characters", "warning"));
            dispatch(
              setCreateRecipeErrorByPath(`sections[${sectionIndex}].source.url`, [
                { msg: "Source URL cannot be longer than 256 characters" },
              ])
            );
            return null;
          }
        }
      }
    }
  }

  const newSections = state.recipe.create.sections.map((section, index) => {
    if (index !== sectionIndex) return section;

    return {
      ...section,
      title: trimmedTitle,
      source: verifiedSource,
    };
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });

  return true;
};

// Remove section
export const deleteSection = (sectionIndex) => (dispatch) => {
  const state = store.getState();

  const sectionName = state.recipe.create.sections[sectionIndex].title;

  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
    steps: [...section.steps],
  }));

  newSections.splice(sectionIndex, 1);

  // Clone errors structure if it exists
  const newErrors = state.recipe.create.errors
    ? JSON.parse(JSON.stringify(state.recipe.create.errors))
    : {
        info: {},
        sections: [],
        dietary: {},
      };

  // Handle error removal if errors exist for this section
  if (newErrors?.sections?.[sectionIndex]) {
    // Remove the error at sectionIndex
    newErrors.sections.splice(sectionIndex, 1);
  }

  // Dispatch updates for both sections and errors
  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });

  if (newErrors?.sections) {
    dispatch({
      type: SET_CREATE_RECIPE_ERRORS,
      payload: newErrors,
    });
  }
  dispatch(setAlert(`Removed: ${sectionName}`, "success"));
};

// Get recipe to edit, prep for editing
export const fetchRecipeToEdit = (recipeid) => async (dispatch) => {
  try {
    const res = await api.get(`/recipes/edit/${recipeid}`);

    dispatch({
      type: GET_EDIT_RECIPE,
      payload: res.data,
    });

    return {
      canEdit: true,
      recipe: res.data,
    };
  } catch (error) {
    return {
      canEdit: false,
    };
  }
};

//Search for recipes to insert as a section

//Get external recipe for section
export const fetchExternalRecipe = (url) => async (dispatch) => {
  try {
    const res = await api.post(
      "/recipes/find",
      { url },
      {
        validateStatus: (status) => {
          return (
            (status >= 200 && status < 300) || status === 429 || status === 404 || status === 503 || status === 408
          );
        },
      }
    );

    if (res.status === 429) {
      //Throw response error
      return {
        error: {
          code: 429,
          msg: "Woah! Too many requests. Try again soon.",
        },
      };
    } else if (res.status === 404) {
      return {
        error: 404,
        msg: "Sorry, this website hasn't set up their recipe to be able to import automatically.",
      };
    } else if (res.status === 406) {
      return {
        error: 406,
        msg: "The URL you entered wasn't valid. Please try again.",
      };
    } else if (res.status === 408) {
      return {
        error: 408,
        msg: "The recipe you're trying to import is taking to long to load. Please try again.",
      };
    } else if (res.status === 503) {
      return {
        error: 503,
        msg: "Recipe importing is currently unavailable. Please refresh the page and try again. If the issue persists, please contact support.",
      };
    }

    //Insert ingredients into each section
    let recipe = res.data.recipe;

    if (!recipe) {
      return {
        error: 404,
        msg: "Recipe not found.",
      };
    }

    dispatch({
      type: GET_EXTERNAL_RECIPE,
      payload: recipe,
    });

    return recipe;
  } catch (error) {
    console.log(error);
    return {
      error: {
        code: 500,
        msg: "Server Error",
      },
    };
  }
};

export const fetchGeneratedRecipe = (query) => async (dispatch) => {
  try {
    /* Route through express server now, DNS fix
    const res = await viper.post(
      "/recipes/generate",
      { text: query },
      {
        validateStatus: (status) => {
          return (status >= 200 && status < 300) || status === 429 ;
        },
      }
    );
    */

    if (query.length > 2048) {
      return {
        error: {
          code: 400,
          msg: "Prompt must be less than 2048 characters",
        },
      };
    }

    const res = await api.post(
      "/recipes/generate",
      { text: query },
      {
        validateStatus: (status) => {
          return (status >= 200 && status < 300) || status === 429;
        },
      }
    );

    if (res.status === 429) {
      //Throw response error
      return {
        error: {
          code: 429,
          msg: "Rate limit. Try again soon.",
        },
      };
    }

    if (res.status === 402) {
      return {
        error: {
          code: 402,
          msg: "Plus is required for this feature.",
        },
      };
    }
    //Insert ingredients into each section
    let recipe = res.data.recipe;

    dispatch({
      type: GET_GENERATED_RECIPE,
      payload: recipe,
    });

    return recipe;
  } catch (error) {
    return {
      error: {
        code: 500,
        msg: "Server Error",
      },
    };
  }
};

export const toggleRecipeMacrosModal = (visible, sectionIndex) => (dispatch) => {
  if (!visible) {
    dispatch({
      type: TOGGLE_RECIPE_MACROS_MODAL,
      payload: {
        visible: false,
        sectionIndex: null,
      },
    });
  } else {
    dispatch({
      type: TOGGLE_RECIPE_MACROS_MODAL,
      payload: {
        visible,
        sectionIndex,
      },
    });
  }
};

export const pushSectionMacro = (sectionIndex, macro) => (dispatch) => {
  const state = store.getState();

  // Validate macro amount
  const amount = parseFloat(macro.amount);
  if (isNaN(amount) || !isFinite(amount) || amount.toString().length > 5) {
    dispatch(setAlert("Macro amount must be a valid number with at most 5 digits", "error"));
    return;
  }

  // Validate macronutrient name
  if (!macro.macronutrient || typeof macro.macronutrient.name !== "string" || macro.macronutrient.name.length > 32) {
    dispatch(setAlert("Invalid macronutrient selected", "error"));
    return;
  }

  const newSections = state.recipe.create.sections.map((section, index) => {
    if (index !== sectionIndex) return section;

    const newMacros = section.macros ? [...section.macros] : [];
    const existingMacroIndex = newMacros.findIndex((m) => m.macronutrient.name === macro.macronutrient.name);

    if (existingMacroIndex !== -1) {
      newMacros[existingMacroIndex] = {
        ...newMacros[existingMacroIndex],
        amount: amount,
      };
    } else {
      newMacros.push({ ...macro, amount: amount });
    }

    return {
      ...section,
      macros: newMacros,
    };
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

export const removeSectionMacro = (sectionIndex, macroIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    macros: section.macros ? [...section.macros] : [],
  }));

  newSections[sectionIndex].macros.splice(macroIndex, 1);

  // Clone errors structure if it exists
  const newErrors = state.recipe.create.errors
    ? JSON.parse(JSON.stringify(state.recipe.create.errors))
    : {
        info: {},
        sections: [],
        dietary: {},
      };

  // Handle error removal if errors exist for this section
  if (newErrors?.sections?.[sectionIndex]?.macros) {
    // Remove the error at ingredientIndex
    newErrors.sections[sectionIndex].macros.splice(macroIndex, 1);

    // Clean up empty error arrays
    if (newErrors.sections[sectionIndex].macros.every((err) => err === null)) {
      delete newErrors.sections[sectionIndex].macros;
    }
    if (Object.keys(newErrors.sections[sectionIndex]).length === 0) {
      newErrors.sections[sectionIndex] = null;
    }
  }

  // Dispatch updates for both sections and errors
  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });

  if (newErrors?.sections) {
    dispatch({
      type: SET_CREATE_RECIPE_ERRORS,
      payload: newErrors,
    });
  }
};

export const setSectionMacroServingSize = (sectionIndex, servingSize) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section, i) => {
    if (i !== sectionIndex) return section;

    return {
      ...section,
      servingSize: servingSize.trim(),
    };
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

export const toggleRecipeOutputModal = (visible, sectionIndex) => (dispatch) => {
  if (!visible) {
    dispatch({
      type: TOGGLE_RECIPE_OUTPUT_MODAL,
      payload: {
        visible: false,
        sectionIndex: null,
      },
    });
  } else {
    dispatch({
      type: TOGGLE_RECIPE_OUTPUT_MODAL,
      payload: {
        visible,
        sectionIndex,
      },
    });
  }
};

export const setSectionOutput = (outputData, servingSize, sectionIndex) => (dispatch) => {
  const state = store.getState();

  //outputData: servings, yield: {amount, unit, ingredient}
  const newSections = state.recipe.create.sections.map((section, i) => {
    if (i !== sectionIndex) return section;

    const returnObj = {
      ...section,
      output: outputData,
    };

    if (servingSize && servingSize !== "") {
      returnObj.servingSize = servingSize;
    } else {
      returnObj.servingSize = "";
    }
    return returnObj;
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

export const toggleRecipeTimingModal = (visible, sectionIndex) => (dispatch) => {
  if (!visible) {
    dispatch({
      type: TOGGLE_RECIPE_TIMING_MODAL,
      payload: {
        visible: false,
        sectionIndex: null,
      },
    });
  } else {
    dispatch({
      type: TOGGLE_RECIPE_TIMING_MODAL,
      payload: {
        visible,
        sectionIndex,
      },
    });
  }
};

export const setSectionTiming = (timingData, sectionIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section, i) => {
    if (i !== sectionIndex) return section;

    return {
      ...section,
      timing: timingData,
    };
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

export const setSectionVideo = (video, sectionIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section, i) => {
    if (i !== sectionIndex) return section;

    return {
      ...section,
      video: video,
    };
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

export const setCreateRecipeErrors = (errors) => (dispatch) => {
  dispatch({
    type: SET_CREATE_RECIPE_ERRORS,
    payload: errors,
  });

  // Helper function to count errors and find first message in an object
  const processErrors = (obj) => {
    let count = 0;
    let firstMessage = null;

    if (!obj) return { count, firstMessage };

    if (Array.isArray(obj)) {
      obj.forEach((error) => {
        if (error && error.msg) {
          count++;
          if (!firstMessage) firstMessage = error.msg;
        } else if (error && typeof error === "object") {
          const { count: nestedCount, firstMessage: nestedMessage } = processErrors(error);
          count += nestedCount;
          if (!firstMessage && nestedMessage) firstMessage = nestedMessage;
        }
      });
    } else if (typeof obj === "object") {
      Object.values(obj).forEach((value) => {
        if (Array.isArray(value)) {
          value.forEach((error) => {
            if (error && error.msg) {
              count++;
              if (!firstMessage) firstMessage = error.msg;
            } else if (error && typeof error === "object") {
              const { count: nestedCount, firstMessage: nestedMessage } = processErrors(error);
              count += nestedCount;
              if (!firstMessage && nestedMessage) firstMessage = nestedMessage;
            }
          });
        } else if (value && typeof value === "object") {
          const { count: nestedCount, firstMessage: nestedMessage } = processErrors(value);
          count += nestedCount;
          if (!firstMessage && nestedMessage) firstMessage = nestedMessage;
        }
      });
    }

    return { count, firstMessage };
  };

  let totalErrors = 0;
  let firstErrorMessage = null;

  Object.entries(errors).forEach(([category, categoryErrors]) => {
    if (category === "sections") {
      categoryErrors.forEach((sectionErrors) => {
        if (sectionErrors) {
          const { count, firstMessage } = processErrors(sectionErrors);
          totalErrors += count;
          if (!firstErrorMessage && firstMessage) firstErrorMessage = firstMessage;
        }
      });
    } else {
      const { count, firstMessage } = processErrors(categoryErrors);
      totalErrors += count;
      if (!firstErrorMessage && firstMessage) firstErrorMessage = firstMessage;
    }
  });

  // Set alert based on error count
  if (totalErrors === 1) {
    dispatch(setAlert(`Found ${totalErrors} error. Please fix it before proceeding.`, "error"));
    dispatch(setAlert(firstErrorMessage, "error"));
  } else if (totalErrors > 1) {
    dispatch(setAlert(`Found ${totalErrors} errors. Please fix them before proceeding.`, "error"));
  }
};
export const resetCreateRecipeErrors = () => (dispatch) => {
  dispatch({
    type: RESET_CREATE_RECIPE_ERRORS,
  });
};
export const clearCreateRecipeErrorByPath = (path) => (dispatch, getState) => {
  const state = getState();
  const newErrors = state.recipe.create.errors
    ? JSON.parse(JSON.stringify(state.recipe.create.errors))
    : {
        info: {},
        sections: [],
        dietary: {},
      };

  const pathArray = path.split(".");
  let current = newErrors;
  let parent = null;
  let parentKey = null;

  // Traverse the path
  for (let i = 0; i < pathArray.length - 1; i++) {
    const key = pathArray[i];
    if (current[key] === undefined) {
      return; // Path doesn't exist, exit early
    }
    parentKey = key;
    parent = current;
    current = current[key];
  }

  const lastKey = pathArray[pathArray.length - 1];

  // Clear the error at the final path
  delete current[lastKey];

  // Clean up empty parent objects recursively, but preserve top-level structure
  while (parent !== null) {
    if (typeof current === "object" && current !== null) {
      const isEmpty =
        Object.keys(current).length === 0 ||
        (Array.isArray(current) && current.every((item) => item === null || item === undefined));

      // Only delete if not at top level
      if (isEmpty && parent !== newErrors) {
        delete parent[parentKey];
      } else if (isEmpty && parent === newErrors) {
        // At top level, preserve structure but set to empty array/object
        if (parentKey === "sections") {
          parent[parentKey] = [];
        } else if (parentKey === "info" || parentKey === "dietary") {
          parent[parentKey] = {};
        }
      }
    }

    // Move up the chain
    current = parent;
    const nextIndex = pathArray.indexOf(parentKey) - 1;
    parentKey = pathArray[nextIndex];
    parent = nextIndex >= 0 ? newErrors : null;

    for (let i = 0; i < nextIndex; i++) {
      parent = parent[pathArray[i]];
    }
  }

  dispatch({
    type: SET_CREATE_RECIPE_ERRORS,
    payload: newErrors,
  });
};

export const setCreateRecipeErrorByPath = (path, errorData) => (dispatch, getState) => {
  const state = getState();
  const newErrors = state.recipe.create.errors
    ? JSON.parse(JSON.stringify(state.recipe.create.errors))
    : {
        info: {},
        sections: [],
        dietary: {},
      };

  const pathArray = path.split(".");
  let current = newErrors;

  // Traverse and create the path if it doesn't exist
  for (let i = 0; i < pathArray.length - 1; i++) {
    const key = pathArray[i];

    // Handle array indices in path
    if (key.includes("[") && key.includes("]")) {
      const arrayKey = key.split("[")[0];
      const index = parseInt(key.split("[")[1].split("]")[0]);

      if (!current[arrayKey]) {
        current[arrayKey] = [];
      }
      if (!current[arrayKey][index]) {
        current[arrayKey][index] = {};
      }
      current = current[arrayKey][index];
    } else {
      if (!current[key]) {
        // Create object or array based on next path segment
        const nextKey = pathArray[i + 1];
        current[key] = nextKey.includes("[") ? [] : {};
      }
      current = current[key];
    }
  }

  // Set the final value
  const lastKey = pathArray[pathArray.length - 1];
  if (lastKey.includes("[") && lastKey.includes("]")) {
    const arrayKey = lastKey.split("[")[0];
    const index = parseInt(lastKey.split("[")[1].split("]")[0]);
    if (!current[arrayKey]) {
      current[arrayKey] = [];
    }
    current[arrayKey][index] = errorData;
  } else {
    current[lastKey] = errorData;
  }

  dispatch({
    type: SET_CREATE_RECIPE_ERRORS,
    payload: newErrors,
  });
};
