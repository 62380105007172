import "cordova-plugin-purchase";
import api from "../../api/api";

declare var CdvPurchase: any;

class InAppPurchaseService {
  private store?: CdvPurchase.Store;
  public initialized?: boolean;
  private appleUserId?: string;

  constructor() {
    this.initializeStore();
  }

  private async initializeStore() {
    console.log("Firing initializeStore");
    document.addEventListener(
      "deviceready",
      () => {
        console.log("Device ready");
        const { store, ProductType, Platform } = CdvPurchase;

        this.store = store;

        this.store.register([
          {
            id: "single_plus_weekly",
            type: ProductType.PAID_SUBSCRIPTION,
            platform: Platform.APPLE_APPSTORE,
          },
          {
            id: "single_plus_monthly",
            type: ProductType.PAID_SUBSCRIPTION,
            platform: Platform.APPLE_APPSTORE,
          },
          {
            id: "single_plus_annual",
            type: ProductType.PAID_SUBSCRIPTION,
            platform: Platform.APPLE_APPSTORE,
          },
        ]);

        console.log("Registered products");

        this.store
          .when()
          .approved((transaction) => transaction.verify())
          .verified((receipt) => receipt.finish())
          .finished((purchase) => console.log("Purchase finished: ", purchase));

        this.store.error((error) => console.error("Store error: ", error));

        this.store.ready(() => console.log("Store is ready: ", this.store.products));

        this.store.initialize([Platform.APPLE_APPSTORE]);

        this.initialized = true;
      },
      false
    );
  }

  async initializeWithUser() {
    try {
      // Use your API wrapper with auth
      const response = await api.get("/apple/user-id");
      const { appleUserId } = response.data;
      this.appleUserId = appleUserId;

      if (this.store) {
        this.store.applicationUsername = appleUserId;
      }

      return appleUserId;
    } catch (error) {
      console.error("Failed to initialize user:", error);
      return null;
    }
  }

  async purchaseSubscription(productId: string) {
    if (!this.appleUserId) {
      try {
        await this.initializeWithUser();
      } catch (error) {
        console.error("Failed to initialize user:", error);
        return null;
      }
    }
    const product = this.store.get(productId);
    console.log("Product: ", product);
    const offer = this.store.get(productId).getOffer();
    console.log("Offer: ", offer);
    if (product && product.canPurchase) {
      try {
        console.log("Ordering: ", productId);
        console.log("Ordering product: ", product);
        console.log("Ordering offer: ", offer);
        await product.getOffer().order({ applicationUsername: this.appleUserId });
        return true;
      } catch (error) {
        console.error("Purchase failed: ", error);
        return null;
      }
    }

    console.log("Product not available for purchase: ", productId);
    return null;
  }

  getProducts() {
    const products = this.store.products;
    console.log("Getting products: ", products);
    return products ? products : [];
  }

  async restorePurchases(userId: string) {
    if (!this.store) return false;

    try {
      if (!this.appleUserId) {
        try {
          await this.initializeWithUser();
        } catch (error) {
          console.error("Failed to initialize user:", error);
          return null;
        }
      }
      console.log("Restoring purchases for user:", userId);
      const result = await this.store.restorePurchases();

      // Handle restored purchases through the existing verification flow
      this.store.when().verified((receipt) => {
        console.log("Restored purchase verified:", receipt);
        return this.handleRestoredPurchase(receipt, userId);
      });

      return true;
    } catch (error) {
      console.error("Restore failed:", error);
      return false;
    }
  }

  private async handleRestoredPurchase(receipt, userId: string) {
    try {
      console.log("Restored Receipt:\n", receipt);
      const response = await api.post("/apple/verify-restored-purchase", {
        receipt,
        userId,
      });

      return true;
    } catch (error) {
      console.error("Receipt verification failed:", error);
      return false;
    }
  }
}

export default new InAppPurchaseService();
