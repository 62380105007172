import React, { useState } from "react";
import { Typography, Divider, ListItem, List, Checkbox } from "@mui/material";

import { Tabs, TabsList, TabsTrigger } from "../../../ui/tabs";

import { ReactComponent as InfoIcon } from "../../../../assets/icons/svg/info.svg";

const MeasuredInfoModal = ({ initialViewType = "ingredients" }) => {
  //New structure
  //- Explain how recipe ingredients get subtracted from pantry, yield gets added as leftovers
  //- Explain measured vs not measured ingredients
  //- Explain how to set up an ingredient

  const [viewType, setViewType] = useState(initialViewType);

  const ingredientsInfo = (
    <div>
      <div className="flex flex-row items-center w-full pt-4">
        <Typography variant="h6" className="font-normal">
          How are ingredients used on thisPantry?
        </Typography>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 py-4">
          <Typography variant="body2" className="text-secondaryText-600">
            On thisPantry, ingredients in recipes can be automatically subtracted from your pantry inventory when you
            cook with them. Every measured ingredient in a recipe section will be subtracted from your pantry inventory
            when you cook with it, and the recipe yield can be added back to your pantry as leftovers.<br></br>
            <br></br>Simply track how much you use or how many servings you make, and we'll do the rest.
          </Typography>
        </div>
        <Divider />
        <div className="flex flex-col gap-6 pt-4">
          <div>
            <div>
              <Typography variant="h6" className="font-normal">
                How do I set up an ingredient?
              </Typography>
              <Typography variant="body2" className="text-secondaryText">
                Here's an example of how a complete ingredient looks with each field:
              </Typography>
            </div>
            <List className="list-disc pl-4 pt-8">
              <ListItem className="list-item marker:text-mainGreen py-1 px-2">
                <div>
                  <span className="flex flex-row items-center">
                    <Typography variant="subtitle1" className="font-medium">
                      <span className="text-primaryText font-normal">3 cups&nbsp;</span>
                      <span className="text-primaryText" style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                        Flour
                      </span>
                      <span className="font-normal italic">, Sifted</span>
                    </Typography>
                  </span>
                  <Typography variant="body2" className="text-secondaryText">
                    (All-Purpose or Whole Wheat)
                  </Typography>
                  <div className="flex flex-row items-center mt-2">
                    <Checkbox
                      className="p-0 mr-1 hover:bg-transparent hover:cursor-default"
                      color="primary"
                      checked={true}
                      disableRipple
                      size="small"
                    />
                    <Typography variant="subtitle2" className="text-secondaryText font-normal text-xs">
                      Measured Ingredient
                    </Typography>
                  </div>
                </div>
              </ListItem>
            </List>
          </div>

          <Divider className="mb-2" />
          <div>
            <Typography variant="subtitle1">
              Amount: <span className="font-normal italic text-mainGreen">3</span>
            </Typography>
            <Typography variant="body2" className="text-secondaryText text-xs">
              The amount of the ingredient you're using. Can be a number or a fraction. Can be a counting amount as in
              "3" in "3 apples", or a measurement amount with a unit as in "1/2" in "1/2 cup of flour".
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1">
              Unit: <span className="font-normal italic text-mainGreen">cups</span>
            </Typography>
            <Typography variant="body2" className="text-secondaryText text-xs">
              The unit of the amount you're using. Can be a unit of measurement like "cups" or "ounces", or a counting
              unit like "pieces" or "servings". A unit must have a corresponding amount value.
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1">
              Ingredient Name: <span className="font-normal italic text-mainGreen">Flour</span>
            </Typography>
            <Typography variant="body2" className="text-secondaryText text-xs">
              The name of the ingredient you're using. We use this to find the ingredient in your pantry.
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1">
              Measured Ingredient: <span className="font-normal italic text-mainGreen">Yes</span>
            </Typography>
            <Typography variant="body2" className="text-secondaryText text-xs">
              Measured ingredients are ingredients that you want to track in your pantry. For example, "3 cups of flour"
              would be a measured ingredient, but "Salt, to taste" would not be.
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1">
              State: <span className="font-normal italic text-mainGreen">Sifted</span>
            </Typography>
            <Typography variant="body2" className="text-secondaryText text-xs">
              The state the ingredient is in. This is optional and can be used to indicate how the ingredient is
              prepared, such as "Sifted", "Diced", or "Frozen".
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1">
              Note: <span className="font-normal italic text-mainGreen">All-Purpose or Whole Wheat</span>
            </Typography>
            <Typography variant="body2" className="text-secondaryText text-xs">
              Optional additional information about the ingredient.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );

  const outputInfo = (
    <div>
      <div className="flex flex-row items-center w-full pt-4">
        <Typography variant="h6" className="font-normal">
          How do output, yield, and servings work?
        </Typography>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 py-4">
          <Typography variant="body2" className="text-secondaryText-600">
            On thisPantry, if you cook a recipe section that has a yield attached you can store the leftovers from the
            yield in your pantry. Section yield is formatted the same way as ingredients, and serving info is how you
            divide that yield up.
            <br></br>
            <br></br>Simply cook a recipe, and we'll automatically add the yield back to your pantry's inventory (minus
            what you eat, of course!).
          </Typography>
        </div>
        <Divider className="mb-2" />
        <div className="flex flex-col gap-6 pt-4">
          <div>
            <div>
              <Typography variant="h6" className="font-normal">
                How do I set up a section's output?
              </Typography>
              <Typography variant="body2" className="text-secondaryText">
                Here's an example of how a complete output looks with each field:
              </Typography>
            </div>
            <div>
              <div className="py-4">
                <Typography variant="subtitle2" className="font-normal">
                  Example Recipe:<br></br>
                  <span className=" text-mainGreen font-medium">Caesar Salad</span>
                </Typography>
              </div>
              <div className="flex flex-col md:flex-row justify-between gap-2 items-start mb-4">
                <div className="flex flex-col gap-2 mb-4 md:mb-0 w-full md:w-1/2">
                  <div className="flex flex-row items-center gap-2">
                    <Typography variant="subtitle2" className="leading-none font-normal">
                      Total Yield:
                    </Typography>
                  </div>
                  <Typography variant="body2" className="leading-none font-normal">
                    <span className="font-normal text-secondaryText-600">6 cups&nbsp;</span>

                    <span className="font-medium">Caesar Salad</span>
                  </Typography>
                </div>
                <div className="flex flex-col gap-4 w-full md:w-1/2">
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-row items-center gap-2">
                      <Typography variant="subtitle2" className="leading-none font-normal">
                        Servings:
                      </Typography>
                    </div>

                    <Typography variant="body2" className="font-normal text-secondaryText-600">
                      4
                    </Typography>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-row items-center gap-2">
                      <Typography variant="subtitle2" className="leading-none font-normal">
                        Serving Size:
                      </Typography>
                    </div>
                    <Typography variant="body2" className="font-normal text-secondaryText-600">
                      1.5 cups
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Divider className="mb-2" />
          <div>
            <div>
              <Typography variant="subtitle1" className="font-normal">
                Total Yield:
              </Typography>
              <Typography variant="body2" className="text-secondaryText text-xs">
                This is the amount of the product or items you make while cooking. Same format as input ingredients.
              </Typography>
            </div>
            <div className="flex flex-col gap-4 py-4 pl-4 md:pl-8">
              <div>
                <Typography variant="subtitle1" className="font-normal">
                  Yield Amount: <span className="font-normal italic text-mainGreen">6</span>
                </Typography>
                <Typography variant="body2" className="text-secondaryText text-xs">
                  The amount of the ingredient or item you're making. Can be a number or a fraction. Can be a counting
                  amount as in "3" in "3 slices", or a measurement amount with a unit as in "1/2" in "1/2 cup of salad".
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle1" className="font-normal">
                  Yield Unit: <span className="font-normal italic text-mainGreen">cups</span>
                </Typography>
                <Typography variant="body2" className="text-secondaryText text-xs">
                  The unit corresponding to the amount for your yield. Can be a unit of measurement like "cups" or
                  "ounces", or a counting unit like "pieces" or "servings". A unit must have a value.
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle1" className="font-normal">
                  Yield Ingredient: <span className="font-normal italic text-mainGreen">Caesar Salad</span>
                </Typography>
                <Typography variant="body2" className="text-secondaryText text-xs">
                  The name of the ingredient or item you're making. This is what is going to be stored in your pantry
                  after you make the recipe section and will be able to be used in other recipes.
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <div>
              <Typography variant="subtitle1" className="font-normal">
                Servings Info:
              </Typography>
              <Typography variant="body2" className="text-secondaryText text-xs">
                How you divide the total yield up into servings.
              </Typography>
            </div>
            <div className="flex flex-col gap-4 py-4 pl-4 md:pl-8">
              <div>
                <Typography variant="subtitle1" className="font-normal">
                  Servings: <span className="font-normal italic text-mainGreen">4</span>
                </Typography>
                <Typography variant="body2" className="text-secondaryText text-xs">
                  The number of people the yield can serve.
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle1" className="font-normal">
                  Serving Size: <span className="font-normal italic text-mainGreen">1.5 cups</span>
                </Typography>
                <Typography variant="body2" className="text-secondaryText text-xs">
                  The amount or size of one single serving of the section's yield (1.5 cups, 2 slices, 1 sandwich).
                  Serving size is also used and listed for macronutrient and nutrition information.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="px-4 pb-8 pt-1 max-h-[80vh] overflow-y-auto">
      <div className="w-full pb-4">
        <Tabs value={viewType} onValueChange={(value) => setViewType(value)} className="w-full">
          <TabsList className="w-full border border-separator p-0">
            <TabsTrigger value="ingredients" className="w-full h-full">
              Input Ingredients
            </TabsTrigger>
            <TabsTrigger value="output" className="w-full h-full">
              Recipe Output
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <div className="md:px-6">{viewType === "ingredients" ? ingredientsInfo : outputInfo}</div>
    </div>
  );
};

export default MeasuredInfoModal;
