import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import api from "../../../api/api";

import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

import { ReactComponent as PlusLeaf } from "../../../assets/icons/svg/leaf-solid.svg";
import { ReactComponent as LinkIcon } from "../../../assets/icons/svg/link.svg";
import ProfileImg from "../../../assets/default/user.jpg";

const Subscribed = ({ profile: { currentProfile }, auth: { plusProvider, plusSince } }) => {
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" });
  };

  const [loading, setLoading] = useState(false);

  const handleManageSubscription = async () => {
    setLoading(true);
    if (plusProvider === "apple") {
      window.location.href = "https://apps.apple.com/account/subscriptions";
    } else {
      try {
        const response = await api.post("/stripe/create-portal-session");
        window.location.href = response.data.url;
      } catch (error) {
        console.error("Error creating portal session:", error);
        // Handle error (e.g., show an error message to the user)
      }
    }
    setLoading(false);
  };

  return (
    <div className="w-full">
      <div className="max-w-2xl mx-auto">
        <div className="flex flex-row items-start">
          {currentProfile.picture === "" ? (
            <img src={ProfileImg} className="w-20 h-20 object-cover rounded-full border border-separator" />
          ) : (
            <img
              src={currentProfile.picture}
              className="w-20 h-20 object-cover rounded-full border border-separator"
            ></img>
          )}

          <div className="flex flex-col gap-1 ml-4">
            <div className="flex flex-row items-center gap-2">
              <Typography variant="h6" className="text-white leading-tight font-normal">
                {currentProfile.name}
              </Typography>
              <PlusLeaf className="h-4 w-auto fill-mainGreen" />
            </div>

            <Typography
              variant="h6"
              className="text-secondaryText-100 leading-tight font-normal"
            >{`@${currentProfile.username}`}</Typography>
            <div className="mt-4">
              <Typography variant="subtitle2" className="font-normal text-white">
                thisPantry+ Member Since:
              </Typography>
              <Typography variant="subtitle2" className="font-normal text-white">
                {plusSince ? formatDate(plusSince) : "N/A"}
              </Typography>
            </div>
          </div>
        </div>
        <div className=" mt-8">
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            disableElevation
            disableRipple
            startIcon={<LinkIcon className="w-3 h-auto fill-primaryText" />}
            className="text-primaryText"
            onClick={handleManageSubscription}
            disabled={loading}
          >
            Subscription Settings
          </Button>
        </div>
      </div>
    </div>
  );
};

Subscribed.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Subscribed);
