import {
  GET_RECIPE_FROM_URL,
  RECIPE_FROM_URL_ERROR,
  RESET_STANDALONE_RECIPE,
  LIKE_RECIPE,
  UNLIKE_RECIPE,
  BOOKMARK_RECIPE,
  UNBOOKMARK_RECIPE,
  RECIPE_CREATED,
} from "./types";
import api from "../api/api";

import { setCreateRecipeErrors } from "./createrecipe";
import { getCroppedImg } from "../utils/image";
import store, { history } from "../store";
import { setAlert } from "./alert";

export const getRecipeFromURL = (username, recipeURL) => async (dispatch) => {
  try {
    const res = await api.get(`/recipes/url/${username}/${recipeURL}`);

    dispatch({
      type: GET_RECIPE_FROM_URL,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: RECIPE_FROM_URL_ERROR,
    });
  }
};

export const resetStandaloneRecipe = () => (dispatch) => {
  dispatch({
    type: RESET_STANDALONE_RECIPE,
  });
};

export const createRecipe = (recipe) => async (dispatch) => {
  try {
    //Form data
    const formData = new FormData();

    //Append cropped images
    const state = store.getState();

    const images = state.media.images;

    let importedImages = [];

    for (let i = 0; i < images.length; i++) {
      const image = images[i];

      if (image.cropped) {
        const croppedImage = await getCroppedImg(image.previewUrl, image.cropped.croppedAreaPixels);

        //Convert dataURL to Blob
        const response = await fetch(croppedImage);
        const blob = await response.blob();
        formData.append("images", blob, `${image._id}.jpg`);
      } else if (image.uploaded && image.source) {
        importedImages.push(image);
      }
    }

    formData.append("importedImages", JSON.stringify(importedImages));

    // Append image order
    formData.append("imageOrder", JSON.stringify(images.map((img) => img._id)));

    //Append recipe data as JSON object
    formData.append("recipe", JSON.stringify(recipe));

    try {
      const response = await api.post("/recipes/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        validateStatus: (status) => {
          return status >= 200 && status < 401;
        },
      });

      if (response.status === 400) {
        console.log(response.data);
        //Set alerts for returned errors
        return response.data;
      } else {
        dispatch({
          type: RECIPE_CREATED,
        });
        // Handle the response
        return { url: response.data.url };
      }
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  } catch (error) {
    console.log(error);
  }
};

export const editRecipe = (recipe, recipeID, originalImages) => async (dispatch) => {
  try {
    //Form data
    const formData = new FormData();

    //Append cropped images
    const state = store.getState();

    const currentImages = state.media.images;

    // Compare current images with original images

    let importedImages = [];

    for (let i = 0; i < currentImages.length; i++) {
      const image = currentImages[i];

      if (image.cropped && !image.uploaded) {
        const croppedImage = await getCroppedImg(image.previewUrl, image.cropped.croppedAreaPixels);

        //Convert dataURL to Blob
        const response = await fetch(croppedImage);
        const blob = await response.blob();
        formData.append("images", blob, `${image._id}.jpg`);
      } else if (image.uploaded && image.source) {
        importedImages.push(image);
      }
    }

    formData.append("importedImages", JSON.stringify(importedImages));

    // Append image order
    formData.append("imageOrder", JSON.stringify(currentImages.map((img) => img._id)));

    //Append recipe data as JSON object
    formData.append("recipe", JSON.stringify(recipe));

    // Send the form data to your backend route
    try {
      const response = await api.post(`/recipes/edit/${recipeID}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        validateStatus: (status) => {
          return status >= 200 && status < 401;
        },
      });

      if (response.status === 400) {
        dispatch(setAlert(response.data, "warning"));
      } else {
        dispatch({
          type: RECIPE_CREATED,
        });
        // Handle the response
        return { url: response.data.url };
      }
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleLikeRecipe = (postID) => async (dispatch) => {
  //Optimistically update state
  const standaloneRecipeState = store.getState().recipe.standalone.recipe;
  const wasLiked = standaloneRecipeState.postData.interactions.hasLiked;
  try {
    dispatch({
      type: wasLiked ? UNLIKE_RECIPE : LIKE_RECIPE,
    });

    //Send request to API
    if (wasLiked) {
      const res = await api.post(`/post/unlike/${postID}`);
    } else {
      const res = await api.post(`/post/like/${postID}`);
    }
  } catch (error) {
    dispatch({
      type: wasLiked ? LIKE_RECIPE : UNLIKE_RECIPE,
    });
  }
};

export const handleBookmarkRecipe = (recipeID) => async (dispatch) => {
  //Optimistically update state
  const standaloneRecipeState = store.getState().recipe.standalone.recipe;
  const wasBookmarked = standaloneRecipeState.postData.interactions.hasBookmarked;
  try {
    dispatch({
      type: wasBookmarked ? UNBOOKMARK_RECIPE : BOOKMARK_RECIPE,
    });

    //Send request to API
    if (wasBookmarked) {
      const res = await api.post(`/recipes/unbookmark/${recipeID}`);
      dispatch(setAlert("Removed recipe from bookmarks", "success"));
    } else {
      const res = await api.post(`/recipes/bookmark/${recipeID}`);
      dispatch(setAlert("Recipe bookmarked", "success"));
    }
  } catch (error) {
    dispatch({
      type: wasBookmarked ? BOOKMARK_RECIPE : UNBOOKMARK_RECIPE,
    });
    dispatch(setAlert("Error bookmarking recipe. Please refresh and try again.", "warning"));
  }
};
