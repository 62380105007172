import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  toggleRecipeTimingModal,
  setSectionTiming,
  setCreateRecipeErrorByPath,
  clearCreateRecipeErrorByPath,
} from "../../../../actions/createrecipe";
import { setAlert } from "../../../../actions/alert";
import CustomModal from "../../../modal/CustomModal";
import Searchable from "../../../layout/searchable/Searchable";

//MUI
import { Typography, TextField, Button } from "@mui/material";

//Icons
import { ReactComponent as ErrorTriangleIcon } from "../../../../assets/icons/svg/error-triangle.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/svg/info.svg";
import { useFetcher } from "react-router-dom";
//Edit macros for a section, or compiled macros for a recipe
const RecipeTimingModal = ({
  visible,
  sectionIndex,
  sections,
  toggleRecipeTimingModal,
  setSectionTiming,
  errors,
  clearCreateRecipeErrorByPath,
  setCreateRecipeErrorByPath,
  setAlert,
}) => {
  const handleClose = () => {
    //Verify and cleanup data before submitting and closing modal
    let hasError = false;

    for (let i = 0; i < Object.keys(timing).length; i++) {
      let type = Object.keys(timing)[i];
      let typeTitle = type.charAt(0).toUpperCase() + type.slice(1);
      let hours = timing[type].hours;
      let minutes = timing[type].minutes;

      //Clear values if both hours and minutes are 0
      if ((hours === 0 || hours.toString().trim() === "") && (minutes === 0 || minutes.toString().trim() === "")) {
        hours = "";
        minutes = "";
      }

      if (hours > 99) {
        setAlert(`${typeTitle} hours must be less than 100`, "error");
        setCreateRecipeErrorByPath(`sections[${sectionIndex}].timing.${type}.hours`, [
          {
            msg: `${typeTitle} hours must be less than 100`,
          },
        ]);
        hasError = true;
      }

      if (hours < 0) {
        setAlert(`${typeTitle} hours cannot be negative`, "error");
        setCreateRecipeErrorByPath(`sections[${sectionIndex}].timing.${type}.hours`, [
          {
            msg: `${typeTitle} hours cannot be negative`,
          },
        ]);
        hasError = true;
      }

      if (minutes < 0) {
        setAlert(`${typeTitle} minutes cannot be negative`, "error");
        setCreateRecipeErrorByPath(`sections[${sectionIndex}].timing.${type}.minutes`, [
          {
            msg: `${typeTitle} minutes cannot be negative`,
          },
        ]);
        hasError = true;
      }

      if (minutes > 59) {
        setAlert(`${typeTitle} minutes must be less than 60`, "error");
        setCreateRecipeErrorByPath(`sections[${sectionIndex}].timing.${type}.minutes`, [
          {
            msg: `${typeTitle} minutes must be less than 60`,
          },
        ]);
        hasError = true;
      }
    }

    if (!hasError) {
      setSectionTiming(timing, sectionIndex);
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.timing`);
      toggleRecipeTimingModal(false);
    }
  };

  const [showPrepTiming, setShowPrepTiming] = useState(false);
  const [showCookTiming, setShowCookTiming] = useState(false);
  const [showRestTiming, setShowRestTiming] = useState(false);

  const handleRemoveTimingType = (type) => {
    setTiming((prevTiming) => {
      let newTiming = { ...prevTiming };
      newTiming[type] = {
        hours: "",
        minutes: "",
      };
      return newTiming;
    });

    //Remove error if exists
    if (errors?.sections?.[sectionIndex]?.timing?.[type]) {
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.timing.${type}`);
    }

    if (type === "prep") {
      setShowPrepTiming(false);
    } else if (type === "cook") {
      setShowCookTiming(false);
    } else if (type === "rest") {
      setShowRestTiming(false);
    }
  };

  useEffect(() => {
    if (sections[sectionIndex]?.timing) {
      setTiming(sections[sectionIndex].timing);

      if (sections[sectionIndex].timing.prep) {
        if (sections[sectionIndex].timing.prep.hours !== "" || sections[sectionIndex].timing.prep.minutes !== "") {
          setShowPrepTiming(true);
        }
      }

      if (sections[sectionIndex].timing.cook) {
        if (sections[sectionIndex].timing.cook.hours !== "" || sections[sectionIndex].timing.cook.minutes !== "") {
          setShowCookTiming(true);
        }
      }

      if (sections[sectionIndex].timing.rest) {
        if (sections[sectionIndex].timing.rest.hours !== "" || sections[sectionIndex].timing.rest.minutes !== "") {
          setShowRestTiming(true);
        }
      }
    }

    return () => {
      setTiming({
        prep: {
          hours: "",
          minutes: "",
        },
        cook: {
          hours: "",
          minutes: "",
        },
        rest: {
          hours: "",
          minutes: "",
        },
        total: {
          hours: "",
          minutes: "",
        },
      });
      setShowPrepTiming(false);
      setShowCookTiming(false);
      setShowRestTiming(false);
    };
  }, [sectionIndex]);
  const [timing, setTiming] = useState({
    prep: {
      hours: sections[sectionIndex]?.timing?.prep?.hours || "",
      minutes: sections[sectionIndex]?.timing?.prep?.minutes || "",
    },
    cook: {
      hours: sections[sectionIndex]?.timing?.cook?.hours || "",
      minutes: sections[sectionIndex]?.timing?.cook?.minutes || "",
    },
    rest: {
      hours: sections[sectionIndex]?.timing?.rest?.hours || "",
      minutes: sections[sectionIndex]?.timing?.rest?.minutes || "",
    },
    total: {
      hours: sections[sectionIndex]?.timing?.total?.hours || "",
      minutes: sections[sectionIndex]?.timing?.total?.minutes || "",
    },
  });

  const handleTimingChange = (type, field, value) => {
    setTiming((prevTime) => {
      try {
        let newHours = prevTime[type].hours === "" ? 0 : parseInt(prevTime[type].hours);
        let newMinutes = prevTime[type].minutes === "" ? 0 : parseInt(prevTime[type].minutes);

        if (field === "hours") {
          newHours = value === "" ? 0 : parseInt(value);
        } else if (field === "minutes") {
          newMinutes = value === "" ? 0 : parseInt(value);
        }

        return { ...prevTime, [type]: { hours: newHours, minutes: newMinutes } };
      } catch (error) {
        console.log(error);
        return { ...prevTime, [type]: { hours: prevTime[type].hours, minutes: prevTime[type].minutes } };
      }
    });
  };

  const handleTimingBlur = (type) => {
    setTiming((prevTime) => {
      let newHours =
        prevTime[type].hours === "" ? (prevTime[type].minutes !== "" ? 0 : "") : parseInt(prevTime[type].hours);
      let newMinutes =
        prevTime[type].minutes === "" ? (prevTime[type].hours !== "" ? 0 : "") : parseInt(prevTime[type].minutes);

      if (newMinutes > 59) {
        newHours += Math.floor(newMinutes / 60);
        newMinutes = newMinutes % 60;
      }

      // Calculate total time from prep, cook, and rest
      if (type !== "total") {
        let totalMinutes = 0;

        // Calculate minutes for current type
        const currentTypeMinutes = (newHours || 0) * 60 + (newMinutes || 0);

        // Add up all times except total
        const types = ["prep", "cook", "rest"];
        types.forEach((timeType) => {
          if (timeType === type) {
            totalMinutes += currentTypeMinutes;
          } else if (prevTime[timeType].hours !== "" || prevTime[timeType].minutes !== "") {
            totalMinutes +=
              (parseInt(prevTime[timeType].hours) || 0) * 60 + (parseInt(prevTime[timeType].minutes) || 0);
          }
        });

        // Update total time if sum is greater than current total
        const currentTotalMinutes =
          (parseInt(prevTime.total.hours) || 0) * 60 + (parseInt(prevTime.total.minutes) || 0);
        if (totalMinutes > currentTotalMinutes) {
          prevTime.total.hours = Math.floor(totalMinutes / 60);
          prevTime.total.minutes = totalMinutes % 60;
        }
      }

      //Error checks
      let setNewError = false;

      if (newHours && newHours !== 0) {
        //Greater than 100
        if (newHours > 99) {
          setCreateRecipeErrorByPath(`sections[${sectionIndex}].timing.${type}.hours`, [
            {
              msg: `${type.charAt(0).toUpperCase() + type.slice(1)} hours must be less than 100`,
            },
          ]);
          setCreateRecipeErrorByPath(`sections[${sectionIndex}].timing.${type}.minutes`, [{ msg: " " }]);
          setNewError = true;
        }

        if (newHours && newHours !== "" && isNaN(newHours)) {
          setCreateRecipeErrorByPath(`sections[${sectionIndex}].timing.${type}.hours`, [
            {
              msg: `${type.charAt(0).toUpperCase() + type.slice(1)} hours must be a number`,
            },
          ]);
          setNewError = true;
        }
      }

      if (!setNewError) {
        //If no new error and existing error, clear error
        clearCreateRecipeErrorByPath(`sections.${sectionIndex}.timing.${type}`);
      }

      //If both 0, reset to empty
      if (newHours === 0 && newMinutes === 0) {
        newHours = "";
        newMinutes = "";

        //Set show values to false
        if (type === "prep") {
          setShowPrepTiming(false);
          //Clear prep error
          clearCreateRecipeErrorByPath(`sections.${sectionIndex}.timing.prep`);
        } else if (type === "cook") {
          setShowCookTiming(false);
          //Clear cook error
          clearCreateRecipeErrorByPath(`sections.${sectionIndex}.timing.cook`);
        } else if (type === "rest") {
          setShowRestTiming(false);
          //Clear rest error
          clearCreateRecipeErrorByPath(`sections.${sectionIndex}.timing.rest`);
        }
      }

      return { ...prevTime, [type]: { hours: newHours, minutes: newMinutes } };
    });
  };
  const showTitleError = (type) => {
    if (
      errors?.sections?.[sectionIndex]?.timing?.[type]?.info ||
      errors?.sections?.[sectionIndex]?.timing?.[type]?.hours ||
      errors?.sections?.[sectionIndex]?.timing?.[type]?.minutes
    ) {
      return true;
    }
    return false;
  };

  return (
    <CustomModal open={visible} handleClose={handleClose}>
      <div className="flex flex-col px-4">
        <Typography variant="h6" className="mb-4 font-normal leading-none">
          {sections[sectionIndex]?.title ? `${sections[sectionIndex]?.title} Timing` : "Section Timing"}
        </Typography>
        <div className="flex flex-col gap-4 pb-4">
          <div>
            <div className="mb-2">
              <div className="flex flex-row items-center gap-2">
                <Typography
                  variant="subtitle1"
                  className={`${showTitleError("total") ? "text-rose-600" : "text-primaryText"} font-normal`}
                >
                  Total Time:
                </Typography>
                {showTitleError("total") && <ErrorTriangleIcon className="w-4 h-4 fill-rose-600" />}
              </div>
              {errors?.sections?.[sectionIndex]?.timing?.total?.info && (
                <div className="flex flex-row items-center gap-2 pb-2">
                  <InfoIcon className="w-3 h-3 fill-secondaryText-600" />
                  <Typography variant="caption" className="text-rose-600">
                    {errors?.sections?.[sectionIndex]?.timing?.total?.info?.map((error) => error.msg).join(", ")}
                  </Typography>
                </div>
              )}
            </div>

            <div className="flex flex-row items-center [&>*:first-child]:mr-1 [&>*:nth-child(2)]:ml-1">
              <TextField
                variant="outlined"
                label="Hours"
                fullWidth
                type="text"
                inputProps={{
                  inputMode: "numeric",
                  maxLength: 3,
                }}
                value={timing.total.hours}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value < 0 || isNaN(value) || value.includes("e")) {
                    event.target.value = 0;
                  }
                  handleTimingChange("total", "hours", event.target.value);
                }}
                onBlur={() => handleTimingBlur("total")}
                error={errors?.sections?.[sectionIndex]?.timing?.total?.hours}
                helperText={errors?.sections?.[sectionIndex]?.timing?.total?.hours
                  ?.map((error) => error.msg)
                  .join(", ")}
              />
              <TextField
                variant="outlined"
                label="Minutes"
                fullWidth
                type="text"
                inputProps={{
                  inputMode: "numeric",
                  maxLength: 3,
                }}
                value={timing.total.minutes}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value < 0 || isNaN(value) || value.includes("e")) {
                    event.target.value = 0;
                  }
                  handleTimingChange("total", "minutes", event.target.value);
                }}
                onBlur={() => handleTimingBlur("total")}
                error={errors?.sections?.[sectionIndex]?.timing?.total?.minutes}
                helperText={errors?.sections?.[sectionIndex]?.timing?.total?.minutes
                  ?.map((error) => error.msg)
                  .join(", ")}
              />
            </div>
          </div>
          <div>
            <div className="mb-2">
              <div className="flex flex-row items-center gap-2">
                <Typography
                  variant="subtitle1"
                  className={`${showTitleError("prep") ? "text-rose-600" : "text-primaryText"} font-normal`}
                >
                  Prep Time:
                </Typography>
                {showTitleError("prep") && <ErrorTriangleIcon className="w-4 h-4 fill-rose-600" />}
              </div>
              {errors?.sections?.[sectionIndex]?.timing?.prep?.info && (
                <div className="flex flex-row items-center gap-2 pb-2">
                  <InfoIcon className="w-3 h-3 fill-secondaryText-600" />
                  <Typography variant="caption" className="text-rose-600">
                    {errors?.sections?.[sectionIndex]?.timing?.prep?.info?.map((error) => error.msg).join(", ")}
                  </Typography>
                </div>
              )}
            </div>
            {showPrepTiming ? (
              <>
                <div className="flex flex-row items-center [&>*:first-child]:mr-1 [&>*:nth-child(2)]:ml-1">
                  <TextField
                    variant="outlined"
                    label="Hours"
                    fullWidth
                    type="text"
                    inputProps={{
                      inputMode: "numeric",
                      maxLength: 3,
                    }}
                    value={timing.prep.hours}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value < 0 || isNaN(value) || value.includes("e")) {
                        event.target.value = 0;
                      }
                      handleTimingChange("prep", "hours", event.target.value);
                    }}
                    onBlur={() => handleTimingBlur("prep")}
                    error={errors?.sections?.[sectionIndex]?.timing?.prep?.hours}
                    helperText={errors?.sections?.[sectionIndex]?.timing?.prep?.hours
                      ?.map((error) => error.msg)
                      .join(", ")}
                  />
                  <TextField
                    variant="outlined"
                    label="Minutes"
                    fullWidth
                    type="text"
                    inputProps={{
                      inputMode: "numeric",
                      maxLength: 3,
                    }}
                    value={timing.prep.minutes}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value < 0 || isNaN(value) || value.includes("e")) {
                        event.target.value = 0;
                      }
                      handleTimingChange("prep", "minutes", event.target.value);
                    }}
                    onBlur={() => handleTimingBlur("prep")}
                    error={errors?.sections?.[sectionIndex]?.timing?.prep?.minutes}
                    helperText={errors?.sections?.[sectionIndex]?.timing?.prep?.minutes
                      ?.map((error) => error.msg)
                      .join(", ")}
                  />
                </div>
                <Button
                  fullWidth
                  disableElevation
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => handleRemoveTimingType("prep")}
                  className="my-2"
                >
                  <Typography variant="caption" className="text-primaryText">
                    - Remove Prep Time
                  </Typography>
                </Button>
              </>
            ) : (
              <Button
                fullWidth
                disableElevation
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => setShowPrepTiming(true)}
              >
                <Typography variant="caption" className="text-primaryText">
                  + Add Prep Time
                </Typography>
              </Button>
            )}
          </div>
          <div>
            <div className="mb-2">
              <div className="flex flex-row items-center gap-2">
                <Typography
                  variant="subtitle1"
                  className={`${showTitleError("cook") ? "text-rose-600" : "text-primaryText"} font-normal`}
                >
                  Cook Time:
                </Typography>
                {showTitleError("cook") && <ErrorTriangleIcon className="w-4 h-4 fill-rose-600" />}
              </div>
              {errors?.sections?.[sectionIndex]?.timing?.cook?.info && (
                <div className="flex flex-row items-center gap-2 pb-2">
                  <InfoIcon className="w-3 h-3 fill-secondaryText-600" />
                  <Typography variant="caption" className="text-rose-600">
                    {errors?.sections?.[sectionIndex]?.timing?.cook?.info?.map((error) => error.msg).join(", ")}
                  </Typography>
                </div>
              )}
            </div>
            {showCookTiming ? (
              <>
                <div className="flex flex-row items-center [&>*:first-child]:mr-1 [&>*:nth-child(2)]:ml-1">
                  <TextField
                    variant="outlined"
                    label="Hours"
                    fullWidth
                    type="text"
                    inputProps={{
                      inputMode: "numeric",
                      maxLength: 3,
                    }}
                    value={timing.cook.hours}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value < 0 || isNaN(value) || value.includes("e")) {
                        event.target.value = 0;
                      }
                      handleTimingChange("cook", "hours", event.target.value);
                    }}
                    onBlur={() => handleTimingBlur("cook")}
                    error={errors?.sections?.[sectionIndex]?.timing?.cook?.hours}
                    helperText={errors?.sections?.[sectionIndex]?.timing?.cook?.hours
                      ?.map((error) => error.msg)
                      .join(", ")}
                  />
                  <TextField
                    variant="outlined"
                    label="Minutes"
                    fullWidth
                    type="text"
                    inputProps={{
                      inputMode: "numeric",
                      maxLength: 3,
                    }}
                    value={timing.cook.minutes}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value < 0 || isNaN(value) || value.includes("e")) {
                        event.target.value = 0;
                      }
                      handleTimingChange("cook", "minutes", event.target.value);
                    }}
                    onBlur={() => handleTimingBlur("cook")}
                    error={errors?.sections?.[sectionIndex]?.timing?.cook?.minutes}
                    helperText={errors?.sections?.[sectionIndex]?.timing?.cook?.minutes
                      ?.map((error) => error.msg)
                      .join(", ")}
                  />
                </div>
                <Button
                  fullWidth
                  disableElevation
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => handleRemoveTimingType("cook")}
                  className="my-2"
                >
                  <Typography variant="caption" className="text-primaryText">
                    - Remove Cook Time
                  </Typography>
                </Button>
              </>
            ) : (
              <Button
                fullWidth
                disableElevation
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => setShowCookTiming(true)}
              >
                <Typography variant="caption" className="text-primaryText">
                  + Add Cook Time
                </Typography>
              </Button>
            )}
          </div>
          <div>
            <div className="mb-2">
              <div className="flex flex-row items-center gap-2">
                <Typography
                  variant="subtitle1"
                  className={`${showTitleError("rest") ? "text-rose-600" : "text-primaryText"} font-normal`}
                >
                  Rest Time:
                </Typography>
                {showTitleError("rest") && <ErrorTriangleIcon className="w-4 h-4 fill-rose-600" />}
              </div>
              {errors?.sections?.[sectionIndex]?.timing?.rest?.info && (
                <div className="flex flex-row items-center gap-2 pb-2">
                  <InfoIcon className="w-3 h-3 fill-secondaryText-600" />
                  <Typography variant="caption" className="text-rose-600">
                    {errors?.sections?.[sectionIndex]?.timing?.rest?.info?.map((error) => error.msg).join(", ")}
                  </Typography>
                </div>
              )}
            </div>
            {showRestTiming ? (
              <>
                <div className="flex flex-row items-center [&>*:first-child]:mr-1 [&>*:nth-child(2)]:ml-1">
                  <TextField
                    variant="outlined"
                    label="Hours"
                    fullWidth
                    type="text"
                    inputProps={{
                      inputMode: "numeric",
                      maxLength: 3,
                    }}
                    value={timing.rest.hours}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value < 0 || isNaN(value) || value.includes("e")) {
                        event.target.value = 0;
                      }
                      handleTimingChange("rest", "hours", event.target.value);
                    }}
                    onBlur={() => handleTimingBlur("rest")}
                    error={errors?.sections?.[sectionIndex]?.timing?.rest?.hours}
                    helperText={errors?.sections?.[sectionIndex]?.timing?.rest?.hours
                      ?.map((error) => error.msg)
                      .join(", ")}
                  />
                  <TextField
                    variant="outlined"
                    label="Minutes"
                    fullWidth
                    type="text"
                    inputProps={{
                      inputMode: "numeric",
                      maxLength: 3,
                    }}
                    value={timing.rest.minutes}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value < 0 || isNaN(value) || value.includes("e")) {
                        event.target.value = 0;
                      }
                      handleTimingChange("rest", "minutes", event.target.value);
                    }}
                    onBlur={() => handleTimingBlur("rest")}
                    error={errors?.sections?.[sectionIndex]?.timing?.rest?.minutes}
                    helperText={errors?.sections?.[sectionIndex]?.timing?.rest?.minutes
                      ?.map((error) => error.msg)
                      .join(", ")}
                  />
                </div>
                <Button
                  fullWidth
                  disableElevation
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => handleRemoveTimingType("rest")}
                  className="my-2"
                >
                  <Typography variant="caption" className="text-primaryText">
                    - Remove Rest Time
                  </Typography>
                </Button>
              </>
            ) : (
              <Button
                fullWidth
                disableElevation
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => setShowRestTiming(true)}
              >
                <Typography variant="caption" className="text-primaryText">
                  + Add Rest Time
                </Typography>
              </Button>
            )}
          </div>
          <div className="">
            <Button variant="contained" color="primary" onClick={handleClose} disableElevation fullWidth>
              Save
            </Button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

RecipeTimingModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  sections: PropTypes.array.isRequired,
  setSectionTiming: PropTypes.func.isRequired,
  toggleRecipeTimingModal: PropTypes.func.isRequired,
  setCreateRecipeErrorByPath: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  clearCreateRecipeErrorByPath: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  visible: state.recipe.create.modals.editTiming.open,
  sectionIndex: state.recipe.create.modals.editTiming.sectionIndex,
  sections: state.recipe.create.sections,
  errors: state.recipe.create.errors,
});

export default connect(mapStateToProps, {
  toggleRecipeTimingModal,
  setSectionTiming,
  setCreateRecipeErrorByPath,
  clearCreateRecipeErrorByPath,
  setAlert,
})(RecipeTimingModal);
