import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Typography, Button } from "@mui/material";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../ui/dropdown-menu";
import { Button as ShadcnButton } from "../../../ui/button";
import { ChevronDown } from "lucide-react";
import { moveIngredient } from "../../../../actions/createrecipe";

const MoveIngredientModal = ({
  recipe: { create },
  sectionIndex,
  ingredientIndex,
  handleClose,
  modalMoveCleanup,
  moveIngredient,
}) => {
  const [selectedSection, setSelectedSection] = useState(sectionIndex);

  const handleMove = () => {
    modalMoveCleanup();
    moveIngredient(sectionIndex, selectedSection, ingredientIndex, -1);
    handleClose();
  };

  return (
    <div className="mx-4 mb-4">
      <Typography variant="h6" className="font-normal mb-4">
        Move Ingredient
      </Typography>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <Typography variant="subtitle2" className="text-secondaryText font-normal">
            Move from:
          </Typography>
          <Typography variant="body1">{create.sections[sectionIndex].title}</Typography>
        </div>

        <div className="flex flex-col gap-1">
          <Typography variant="subtitle2" className="text-secondaryText font-normal">
            To:
          </Typography>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <ShadcnButton
                variant="outline"
                className="w-full justify-between font-normal rounded-lg border-secondaryText-200 hover:border-primaryText font-sans text-md"
              >
                {create.sections[selectedSection].title}
                <ChevronDown className="w-3 opacity-50 ml-2" />
              </ShadcnButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] rounded-[8px] z-[1301]">
              {create.sections.map((section, idx) => (
                <DropdownMenuItem key={idx} onSelect={() => setSelectedSection(idx)}>
                  {section.title}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <Button
          variant="contained"
          onClick={handleMove}
          disabled={selectedSection === sectionIndex}
          fullWidth
          disableElevation
        >
          Move Ingredient
        </Button>
      </div>
    </div>
  );
};

MoveIngredientModal.propTypes = {
  recipe: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  ingredientIndex: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  onModalMove: PropTypes.func.isRequired,
  moveIngredient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  recipe: state.recipe,
});

export default connect(mapStateToProps, { moveIngredient })(MoveIngredientModal);
