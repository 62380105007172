import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, Button, IconButton, TextField, Divider } from "@mui/material";

//Actions
import {
  deleteSection,
  editSectionInfo,
  setCreateRecipeErrorByPath,
  clearCreateRecipeErrorByPath,
} from "../../../../actions/createrecipe";

import { setAlert } from "../../../../actions/alert";

//Icons
import { ReactComponent as PlusIcon } from "../../../../assets/icons/svg/leaf-solid.svg";
import { ReactComponent as ChevronDown } from "../../../../assets/icons/svg/chevron-down.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/svg/trash.svg";
import { ReactComponent as TrashCheckIcon } from "../../../../assets/icons/svg/trash-check.svg";

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../ui/dropdown-menu";
import { Button as ShadcnButton } from "../../../ui/button";

const RecipeEditSectionModal = ({
  section,
  sectionIndex,
  deleteSection,
  editSectionInfo,
  handleClose,
  errors,
  setAlert,
  setCreateRecipeErrorByPath,
  clearCreateRecipeErrorByPath,
}) => {
  const [title, setTitle] = useState(section.title);
  const [source, setSource] = useState(section.source);

  //Verification functions
  const verifySectionTitle = (value) => {
    let hasErrors = false;
    let preventSetValue = false;

    if (value.trim() === "") {
      hasErrors = true;
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.title`, [{ msg: "Section title cannot be empty" }]);
    }

    if (value.length > 50) {
      hasErrors = true;
      preventSetValue = true;
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.title`, [
        { msg: "Section title cannot be longer than 50 characters" },
      ]);
    }

    if (typeof value !== "string") {
      hasErrors = true;
      preventSetValue = true;
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.title`, [{ msg: "Invalid section title format" }]);
    }

    return {
      hasErrors,
      preventSetValue,
    };
  };

  const handleTitleChange = (e) => {
    let value = e.target.value;

    const { hasErrors, preventSetValue } = verifySectionTitle(value);

    if (!preventSetValue) {
      setTitle(value);
    }

    if (!hasErrors) {
      if (errors?.sections?.[sectionIndex]?.title) {
        clearCreateRecipeErrorByPath(`sections.${sectionIndex}.title`);
      }
    }
  };

  const [deleteAreYouSure, setDeleteAreYouSure] = useState(false);

  const handleDelete = () => {
    deleteSection(sectionIndex);
    handleClose();
  };

  const handleSave = (e) => {
    //Stop higher forms from firing
    e.stopPropagation();
    e.preventDefault();

    let hasErrors = false;

    const { hasErrors: titleHasErrors } = verifySectionTitle(title);
    const { hasErrors: sourceHasErrors } = verifySourceText(source);
    const { hasErrors: sourceUrlHasErrors } = verifySourceUrl(source);

    let success = editSectionInfo(sectionIndex, title.trim(), source);

    if (success && !titleHasErrors && !sourceHasErrors && !sourceUrlHasErrors) {
      handleClose();
    } else {
      setAlert("Please fix the errors before saving", "warning");
    }
  };

  const handleAddSource = () => {
    setSource({
      text: "",
      url: "",
      type: "Add a source",
    });
  };

  const handleRemoveSource = () => {
    setSource(null);
  };

  const handleSourceTypeChange = (value) => {
    if (value === "Add a source") {
      handleRemoveSource();
    } else {
      setSource({
        ...source,
        type: value,
      });
    }
  };

  const verifySourceText = (value) => {
    if (!value) {
      return {
        hasErrors: false,
        preventSetValue: false,
      };
    }

    let hasErrors = false;
    let preventSetValue = false;

    if (value.length > 128) {
      hasErrors = true;
      preventSetValue = true;
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.source.text`, [
        { msg: "Source text cannot be longer than 128 characters" },
      ]);
    }

    return {
      hasErrors,
      preventSetValue,
    };
  };

  const handleSourceTextChange = (e) => {
    let value = e.target.value;

    const { hasErrors, preventSetValue } = verifySourceText(value);

    if (!preventSetValue) {
      setSource({
        ...source,
        text: e.target.value,
      });
    }

    if (!hasErrors) {
      if (errors?.sections?.[sectionIndex]?.source?.text) {
        clearCreateRecipeErrorByPath(`sections.${sectionIndex}.source.text`);
      }
    }
  };

  const verifySourceUrl = (value) => {
    if (!value) {
      return {
        hasErrors: false,
        preventSetValue: false,
      };
    }

    let hasErrors = false;
    let preventSetValue = false;

    if (value.length > 256) {
      hasErrors = true;
      preventSetValue = true;
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.source.url`, [
        { msg: "Source URL cannot be longer than 256 characters" },
      ]);
    }

    return {
      hasErrors,
      preventSetValue,
    };
  };

  const handleSourceUrlChange = (e) => {
    let value = e.target.value;

    const { hasErrors, preventSetValue } = verifySourceUrl(value);

    if (!preventSetValue) {
      setSource({
        ...source,
        url: e.target.value,
      });
    }

    if (!hasErrors) {
      if (errors?.sections?.[sectionIndex]?.source?.url) {
        clearCreateRecipeErrorByPath(`sections.${sectionIndex}.source.url`);
      }
    }
  };

  const canEditSource = () => {
    if (source && source.ref) {
      return false;
    }
    return true;
  };

  const showSource = () => {
    if (source) {
      if (source.ref) {
        return false;
      }
      if (source.type && source.type.length > 0) {
        return true;
      }
      if (source.url && source.url.length > 0) {
        return true;
      }
      if (source.text && source.text.length > 0) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="mx-4 mb-4">
      <form onSubmit={(e) => handleSave(e)}>
        <div className="flex flex-row justify-between items-center">
          <Typography variant="h6" className="font-normal leading-none">
            Edit Section
          </Typography>
          {deleteAreYouSure ? (
            <Button variant="contained" color="secondary" onClick={() => handleDelete()} disableElevation>
              <TrashCheckIcon className="h-4 w-4 fill-primaryText" />
            </Button>
          ) : (
            <Button variant="contained" color="secondary" onClick={() => setDeleteAreYouSure(true)} disableElevation>
              <TrashIcon className="h-4 w-4 fill-primaryText" />
            </Button>
          )}
        </div>
        <div className="my-4">
          <TextField
            value={title}
            onChange={(e) => handleTitleChange(e)}
            variant="outlined"
            fullWidth
            label="Title"
            error={errors?.sections?.[sectionIndex]?.title}
            helperText={errors?.sections?.[sectionIndex]?.title?.[0]?.msg}
          />
        </div>
        <div>
          {showSource() ? (
            <div>
              <div className="flex flex-col gap-3">
                <div className="mb-2">
                  <Divider />
                  <div className="py-4">
                    <Typography variant="subtitle1" className="font-normal">
                      Source:
                    </Typography>
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <ShadcnButton
                        variant="outline"
                        className={`w-full justify-between font-normal rounded-lg border-secondaryText-200 hover:border-primaryText font-sans text-md`}
                        disabled={source?.ref}
                      >
                        {source && source.type}
                        <ChevronDown className="w-3 opacity-50" />
                      </ShadcnButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] rounded-[8px] z-[1301]">
                      <DropdownMenuItem
                        onSelect={() => handleSourceTypeChange("Add a source")}
                        className="text-secondaryText-600/90"
                      >
                        Remove Source
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Inspired by")}>
                        Inspired by
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Adapted from")}>
                        Adapted from
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Based on")}>Based on</DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("A take on")}>
                        A take on
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Found in")}>Found in</DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Handed down from")}>
                        Handed down from
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Influenced by")}>
                        Influenced by
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Generated with")}>
                        Generated with
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleSourceTypeChange("Imported from")}>
                        Imported from
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <div>
                  <TextField
                    value={source.text}
                    onChange={(e) => handleSourceTextChange(e)}
                    variant="outlined"
                    fullWidth
                    label="Source Text"
                    size="small"
                    helperText="Name of the recipe source"
                    disabled={source.ref}
                  />
                </div>
                <div>
                  <TextField
                    value={source.url}
                    onChange={(e) => handleSourceUrlChange(e)}
                    variant="outlined"
                    fullWidth
                    label="Source URL"
                    size="small"
                    helperText="Add a link to the recipe source"
                    disabled={source.ref}
                  />
                </div>
                <div className="mb-4">
                  <Button
                    onClick={() => handleSourceTypeChange("Add a source")}
                    className="px-0 hover:bg-transparent font-normal"
                    disableRipple
                  >
                    <Typography variant="subtitle2" className="font-normal text-secondaryText-600 text-xs">
                      - Remove Source
                    </Typography>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <>
              {canEditSource() && (
                <Button
                  onClick={() => handleAddSource()}
                  className="px-0 hover:bg-transparent font-normal"
                  disableRipple
                >
                  <Typography variant="subtitle2" className="font-normal text-secondaryText-600 text-xs">
                    + Add a Source
                  </Typography>
                </Button>
              )}
            </>
          )}
          {source?.ref && (
            <Typography variant="subtitle2" className="text-secondaryText italic text-xs leading-snug font-normal mb-4">
              This section's source was automatically generated when importing or generating a recipe and cannot be
              edited
            </Typography>
          )}
        </div>
        <div className="flex flex-row justify-between mt-2">
          <Button variant="contained" color="primary" disableElevation type="submit" fullWidth>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

RecipeEditSectionModal.propTypes = {
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  deleteSection: PropTypes.func.isRequired,
  editSectionInfo: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setCreateRecipeErrorByPath: PropTypes.func.isRequired,
  clearCreateRecipeErrorByPath: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.recipe.create.errors,
});

export default connect(mapStateToProps, {
  deleteSection,
  editSectionInfo,
  setCreateRecipeErrorByPath,
  clearCreateRecipeErrorByPath,
  setAlert,
})(RecipeEditSectionModal);
