import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

//MUI
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

//Icons
import { ReactComponent as PlusIcon } from "../../assets/icons/svg/plus.svg";

//Infinite scroll
import InfiniteScroll from "react-infinite-scroll-component";

//Components
import Spinner from "../layout/Spinner";
import PostManager from "./PostManager";
import UpdateManager from "../home/UpdateManager";
import FollowSuggestions from "../profile/FollowSuggestions";
import EmptyFeed from "./EmptyFeed";
import EndOfFeed from "./EndOfFeed";
import PlusUpgrade from "./PlusUpgrade";

//Ads
import InFeedAd from "../ads/feed/InFeedAd";

//Actions
import { getHomeFeed, refreshHomeFeed } from "../../actions/feed";
import { toggleCreateModal } from "../../actions/navigation";

//Notifications prompt
import { initializeNotifications } from "../../utils/notifications";

//iOS App Tracking Transparency Prompt
import { AppTrackingTransparency } from "capacitor-plugin-app-tracking-transparency";

const FeedManager = ({
  getHomeFeed,
  refreshHomeFeed,
  feed: { posts, loading, isEndOfFeed },
  toggleCreateModal,
  auth: { isPlus },
}) => {
  //iOS App Tracking Transparency
  const checkiOSATT = async () => {
    try {
      const status = await AppTrackingTransparency.getStatus();
      console.log("iOS App Tracking Transparency Status:");
      console.log(status?.status);

      if (status && status.status === "notDetermined") {
        const result = await AppTrackingTransparency.requestPermission();
        console.log("iOS App Tracking Transparency Result:");
        console.log(result?.status);
      }
    } catch (error) {
      console.log("iOS App Tracking Transparency Error", error);
    }
  };

  useEffect(() => {
    checkiOSATT();
  }, []);

  useEffect(() => {
    //TODO: Scroll to last feed position
    refreshHomeFeed();
  }, []);

  useEffect(() => {
    initializeNotifications();
  }, []);

  const fetchMoreData = () => {
    if (!loading) {
      getHomeFeed();
    }
  };

  const refreshFeed = () => {
    refreshHomeFeed();
  };

  /*
  const handleRefresh = () => {
    console.log("ur ");
  };

  //Ad after X posts every Y
    {(i === 4 || (i > 4 && (i - 4) % 3 === 0)) && <InFeedAd key={`ad-${i}`} />}
*/
  return (
    <div className="w-full flex flex-col items-center justify-center md:pl-[5rem]">
      <UpdateManager />
      <div className="w-full max-w-[768px] md:max-w-[600px] flex flex-col items-center [&_>_div:first-child]:w-full [&_>_div:first-child]:overflow-x-hidden safe-nav-padding [&_>_div]:w-full ">
        <div className="flex flex-row justify-between md:rounded-lg border-t md:border border-separator shadow-sm bg-background">
          <div className="w-1/2 p-4">
            <Link to="/create/post">
              <div className="flex flex-row gap-2 items-center">
                <PlusIcon className="h-[0.75rem] fill-mainGreen" />
                <Typography variant="subtitle1" className="font-normal text-primaryText leading-snug">
                  Create Post
                </Typography>
              </div>

              <Typography variant="caption" className="text-secondaryText hidden md:inline-block">
                Share your culinary creations
              </Typography>
            </Link>
          </div>

          <div className="w-1/2 border-l border-separator p-4">
            <Button
              onClick={() => toggleCreateModal(true)}
              disableRipple
              className="p-0 flex flex-col items-start justify-between h-full hover:bg-transparent"
            >
              <div className="flex flex-row gap-2 items-center">
                <PlusIcon className="h-[0.75rem] fill-mainGreen" />
                <Typography variant="subtitle1" className="font-normal text-primaryText leading-snug">
                  Add Recipe
                </Typography>
              </div>

              <Typography variant="caption" className="text-secondaryText hidden md:inline-block">
                Create, Generate, or Import
              </Typography>
            </Button>
          </div>
        </div>
        {loading ? (
          <Spinner classNameOverride="mt-8" />
        ) : posts && posts.length > 0 ? (
          <InfiniteScroll
            dataLength={posts ? posts.length : 0}
            next={fetchMoreData}
            hasMore={!isEndOfFeed}
            loader={loading && <Spinner classNameOverride="mt-8" />}
            pullDownToRefresh
            refreshFunction={refreshFeed}
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={<h3 className="text-center mb-4">&#8595; Pull down to refresh</h3>}
            releaseToRefreshContent={<h3 className="text-center mb-4">&#8593; Release to refresh</h3>}
            endMessage={<EndOfFeed />}
            className="h-full w-full [&_>_div]:md:mb-4"
          >
            {posts &&
              posts.map((post, i) => (
                <Fragment key={i}>
                  <PostManager index={i} />
                  {(i === 5 || (i > 5 && (i - 5) % 25) === 0) && i !== posts.length - 1 && (
                    <FollowSuggestions key={`profile-suggestions-${i}`} />
                  )}
                  {!isPlus && (i === 7 || (i > 7 && (i - 7) % 20 === 0)) && <PlusUpgrade key={`plus-upgrade-${i}`} />}
                </Fragment>
              ))}
          </InfiniteScroll>
        ) : (
          <EmptyFeed />
        )}
      </div>
    </div>
  );
};

/**
 *
 */

FeedManager.propTypes = {
  // isAuthenticated: PropTypes.bool,
  getHomeFeed: PropTypes.func.isRequired,
  refreshHomeFeed: PropTypes.func.isRequired,
  feed: PropTypes.object.isRequired,
  toggleCreateModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  feed: state.feed,
  auth: state.auth,
});

export default connect(mapStateToProps, { getHomeFeed, refreshHomeFeed, toggleCreateModal })(FeedManager);
