//Import redux types
import {
  RECIPE_CREATE_UPDATE,
  RECIPE_CREATE_FORMAT_INGRED,
  RECIPE_CREATE_INGRED_ERROR,
  RECIPE_FROM_URL_ERROR,
  RESET_STANDALONE_RECIPE,
  GET_RECIPE_FROM_URL,
  LIKE_RECIPE,
  UNLIKE_RECIPE,
  BOOKMARK_RECIPE,
  UNBOOKMARK_RECIPE,
  RECIPE_CREATED,
  CREATE_RECIPE_REVIEW,
  GET_EDIT_RECIPE,
  GET_EXTERNAL_RECIPE,
  GET_GENERATED_RECIPE,
  RECIPE_CREATE_RESET,
  TOGGLE_RECIPE_MACROS_MODAL,
  TOGGLE_RECIPE_OUTPUT_MODAL,
  TOGGLE_RECIPE_TIMING_MODAL,
  SET_CREATE_RECIPE_ERRORS,
  RESET_CREATE_RECIPE_ERRORS,
  CLEAR_CREATE_RECIPE_ERROR_PATH,
} from "../actions/types";

//Initial state object
const initialState = {
  create: {
    sections: [],
    loading: false,
    ingredientsProcessing: {
      loading: false,
      error: false,
    },
    errors: {
      info: {},
      sections: [],
      dietary: {},
    },
    modals: {
      addIngredients: {
        open: false,
        sectionIndex: null,
      },
      addSteps: {
        open: false,
        sectionIndex: null,
      },
      editIngredient: {
        open: false,
        sectionIndex: null,
        ingredientIndex: null,
      },
      editStep: {
        open: false,
        sectionIndex: null,
        stepIndex: null,
      },
      editMacros: {
        open: false,
        sectionIndex: null,
      },
      editOutput: {
        open: false,
        sectionIndex: null,
      },
      editTiming: {
        open: false,
        sectionIndex: null,
      },
    },
  },
  edit: {
    recipe: null,
  },
  standalone: {
    recipe: null,
    loading: true,
    error: false,
  },
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (action.type) {
    case CREATE_RECIPE_REVIEW: //Update standalone myReview state if standalone recipe
      if (state.standalone.recipe) {
        return {
          ...state,
          standalone: {
            ...state.standalone,
            interactions: {
              ...state.standalone.interactions,
              reviewsData: {
                ...state.standalone.interactions.reviewsData,
                myReview: payload,
                aggregatedRating:
                  state.standalone.interactions.reviewsData.aggregatedRating === -1
                    ? payload.rating
                    : state.standalone.interactions.reviewsData.aggregatedRating, //Only update rating if it's the first one
                count: state.standalone.interactions.reviewsData.count + 1,
                //TO DO: Handle distributions or return reviewsData from the postReview route for that recipe on review creation?
              },
            },
          },
        };
      } else {
        return state;
      }
    case RECIPE_CREATE_UPDATE:
      return {
        ...state,

        create: {
          ...state.create,
          loading: false,
          sections: payload,
          ingredientsProcessing: {
            loading: false,
            error: false,
          },
        },
      };
    case RECIPE_CREATE_FORMAT_INGRED: {
      return {
        ...state,
        create: {
          ...state.create,
          ingredientsProcessing: {
            loading: true,
            error: false,
          },
        },
      };
    }
    case RECIPE_CREATE_INGRED_ERROR: {
      return {
        ...state,
        create: {
          ...state.create,
          sections: payload ? payload : state.create.sections,
          ingredientsProcessing: {
            loading: false,
            error: true,
          },
        },
      };
    }
    case GET_RECIPE_FROM_URL:
      return {
        ...state,
        standalone: {
          recipe: payload,
          loading: false,
          error: false,
        },
      };
    case RECIPE_FROM_URL_ERROR:
      return {
        ...state,
        standalone: {
          recipe: null,
          loading: false,
          error: true,
        },
      };
    case RESET_STANDALONE_RECIPE:
      return {
        ...state,
        standalone: {
          recipe: null,
          loading: true,
          error: false,
        },
      };
    case LIKE_RECIPE:
      return {
        ...state,
        standalone: {
          ...state.standalone,
          recipe: {
            ...state.standalone.recipe,
            postData: {
              ...state.standalone.recipe.postData,
              interactions: {
                ...state.standalone.recipe.postData.interactions,
                likesCount: state.standalone.recipe.postData.interactions.likesCount + 1,
                hasLiked: true,
              },
            },
          },
        },
      };
    case UNLIKE_RECIPE:
      return {
        ...state,
        standalone: {
          ...state.standalone,
          recipe: {
            ...state.standalone.recipe,
            postData: {
              ...state.standalone.recipe.postData,
              interactions: {
                ...state.standalone.recipe.postData.interactions,
                likesCount:
                  state.standalone.recipe.postData.likesCount - 1 >= 0
                    ? state.standalone.recipe.postData.likesCount - 1
                    : 0,
                hasLiked: false,
              },
            },
          },
        },
      };
    case BOOKMARK_RECIPE:
      return {
        ...state,
        standalone: {
          ...state.standalone,
          recipe: {
            ...state.standalone.recipe,
            postData: {
              ...state.standalone.recipe.postData,
              interactions: {
                ...state.standalone.recipe.postData.interactions,
                bookmarksCount: state.standalone.recipe.postData.interactions.bookmarksCount + 1,
                hasBookmarked: true,
              },
            },
          },
        },
      };
    case UNBOOKMARK_RECIPE:
      return {
        ...state,
        standalone: {
          ...state.standalone,
          recipe: {
            ...state.standalone.recipe,
            postData: {
              ...state.standalone.recipe.postData,
              interactions: {
                ...state.standalone.recipe.postData.interactions,
                bookmarksCount:
                  state.standalone.recipe.postData.bookmarksCount - 1 >= 0
                    ? state.standalone.recipe.postData.bookmarksCount - 1
                    : 0,
                hasBookmarked: false,
              },
            },
          },
        },
      };
    case GET_EDIT_RECIPE:
      return {
        ...state,
        edit: {
          recipe: payload,
        },
        create: {
          ...state.create,
          sections: payload.sections,
        },
      };
    case GET_EXTERNAL_RECIPE:
      return {
        ...state,
        create: {
          ...state.create,
          sections: [...state.create.sections, ...payload.sections],
        },
      };
    case GET_GENERATED_RECIPE:
      return {
        ...state,
        create: {
          ...state.create,
          sections: [...state.create.sections, ...payload.sections],
        },
      };

    case TOGGLE_RECIPE_MACROS_MODAL:
      return {
        ...state,
        create: {
          ...state.create,
          modals: {
            ...state.create.modals,
            editMacros: {
              open: payload.visible,
              sectionIndex: payload.sectionIndex,
            },
          },
        },
      };
    case TOGGLE_RECIPE_OUTPUT_MODAL:
      return {
        ...state,
        create: {
          ...state.create,
          modals: {
            ...state.create.modals,
            editOutput: {
              open: payload.visible,
              sectionIndex: payload.sectionIndex,
            },
          },
        },
      };
    case TOGGLE_RECIPE_TIMING_MODAL:
      return {
        ...state,
        create: {
          ...state.create,
          modals: {
            ...state.create.modals,
            editTiming: {
              open: payload.visible,
              sectionIndex: payload.sectionIndex,
            },
          },
        },
      };
    case RECIPE_CREATED:
    case RECIPE_CREATE_RESET:
      return {
        ...state,
        create: initialState.create,
        edit: initialState.edit,
      };
    case SET_CREATE_RECIPE_ERRORS:
      return {
        ...state,
        create: {
          ...state.create,
          errors: payload,
        },
      };
    case RESET_CREATE_RECIPE_ERRORS:
      return {
        ...state,
        create: {
          ...state.create,
          errors: initialState.create.errors,
        },
      };
    case CLEAR_CREATE_RECIPE_ERROR_PATH:
      return {
        ...state,
        create: {
          ...state.create,
          errors: payload,
        },
      };
    default:
      return state;
  }
}
